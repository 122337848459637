(function(angular) {
	'use strict';

	angular.module('xpsui:directives')
	.directive('xpsuiPortalWidgetJobsMainEdit', ['xpsui:logging', '$compile', function(log, $compile) {
		return {
			restrict: 'A',
			scope: {
				data: '=xpsuiPortalWidgetJobsMainEdit',
				index: '='
			},
			require: ['^xpsuiPortalArticleContentEdit'],
			link: function(scope, elm, attrs, ctrls) {
				log.group('portal-widget-jobs-main-edit Link');

				elm.empty();
				elm.addClass('x-portal-widget-edit');
				elm.addClass('x-portal-widget-jobs-main-edit');

				var titleBar = angular.element('<div class="xpsui-portal-widget-title-bar">{{data.meta.type}}:{{data.meta.name}}<div class="pull-right-widget"><i class="action-button icon-add" ng-click="add();"></i><i class="action-button icon-chevron-up" ng-click="moveUp();"></i><i class="action-button icon-chevron-down" ng-click="moveDown();"></i><i class="action-button icon-trash" ng-click="remove();"></i></div></div>');
//				var content = angular.element('<div class="psui-wrapper" style="text-align: center;"><psui-uploadable-image psui-imageresizor="" psui-imageresizor-width="656" psui-imageresizor-height="492" ng-model="data.data.img" style="width: 656px !important; height: 492px !important; background-image: url(https://localhost:3443/img/no_image.jpg); margin:0;"></psui-uploadable-image></div>');

				var content = angular.element('<div class="x-portal-widget-jobs-main-edit-content jobsBlock">' +
					'<textarea class="x-portal-widget-jobs-main-edit-text" ng-model="data.data.title" placeholder="Enterjobs\'s section title.."></textarea>' +
					'<textarea class="x-portal-widget-jobs-main-edit-text" ng-model="data.data.caption" placeholder="Enter jobs\'s section caption.."></textarea>' +
						'<div ng-repeat="photo in data.data.position" class="psui-wrapper x-portal-widget-jobs-main-edit-block" style="padding: 0px; margin: 20px; position: relative;">' +
							'<div style="position: absolute; top: 0;">' +
								'<i class="action-button icon-chevron-left" ng-click="photoLeft($index);"></i>'+
								'<i class="action-button icon-trash" ng-click="photoRemove($index);"></i>'+
								'<i class="action-button icon-chevron-right" ng-click="photoRight($index);"></i>'+
							'</div>' +
							'<textarea class="x-portal-widget-jobs-main-edit-text" ng-model="photo.position" placeholder="Enter Job\'s description.."></textarea>' +
							'<textarea class="x-portal-widget-jobs-main-edit-text" ng-model="photo.link" placeholder="Enter Job\'s page url.."></textarea>' +
						'</div>' +
						'<textarea class="x-portal-widget-jobs-main-edit-text" ng-model="data.data.remaining" placeholder="Enter job\'s section main button details"></textarea>' +
					'</div>');
				elm.append(titleBar);
				elm.append(content);

				$compile(titleBar)(scope);
				$compile(content)(scope);

				scope.moveUp = function() {
					ctrls[0].moveUp(scope.index);
				};

				scope.moveDown = function() {
					ctrls[0].moveDown(scope.index);
				};

				scope.remove = function() {
					ctrls[0].remove(scope.index);
				};

				scope.add = function() {
					scope.data.data.position.push({
					});
				};

				scope.photoLeft = function(idx) {
					if (idx > 0) {
						var tmp = scope.data.data.position[idx];
					
						scope.data.data.position.splice(idx, 1);
						scope.data.data.position.splice(idx-1, 0, tmp);
					}
				};

				scope.photoRight = function(idx) {
					if (idx < scope.data.data.position.length - 1) {
						var tmp = scope.data.data.position[idx];
					
						scope.data.data.position.splice(idx, 1);
						scope.data.data.position.splice(idx+1, 0, tmp);
					}
				};

				scope.photoRemove = function(idx) {
						scope.data.data.position.splice(idx, 1);
				};

				log.groupEnd();
			}
		};
	}]);

}(window.angular));


