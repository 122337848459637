(function(angular) {
	'use strict';

	angular.module('xpsui:directives')
	.directive('xpsuiPortalWidgetListOfEventView', ['xpsui:logging', '$compile', function(log, $compile) {
		return {
			restrict: 'A',
			scope: {
				data: '=xpsuiPortalWidgetListOfEventView'
			},
			link: function(scope, elm, attrs, ctrls) {
                log.group('portal-widget-list-of-event-view Link');

                elm.empty();
                elm.addClass('x-portal-widget-view');

                var content = angular.element('<div class="x-portal-widget-match-results">' +
                    '<section class="x-portal-competition-matches">' +
                        '<header>{{data.meta.title}}</header>' +
                        '<div>Tag článku, na ktorom sa nachádza widget detail udalosti: {{ data.prelinkArticleTag }}</div>' +
                        '<div>Filtrovanie podla typu:' +
                        '<div ng-repeat="tpe in data.criteriumTypeArray.type" style="display: inline-block; padding-left: 1px;">' +
                            '<p style="margin:0px 5px;" >{{tpe.title}} |</p>' +
                        '</div></div>' +
                    '</section>' +
                    '</div>');
                
                elm.append(content);
                $compile(content)(scope);

                log.groupEnd();

                scope.makeSafe = function(str) {
                    if (typeof str === 'string') {
                        return $sce.trustAsHtml(str);
                    }

                    return '';
                };

            }
		};
	}]);

}(window.angular));

