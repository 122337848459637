(function(angular) {
	'use strict';

	angular.module('xpsui:directives')
	.directive('xpsuiPortalWidgetListOfEventEdit', ['xpsui:logging', '$compile', function(log, $compile) {
		return {
			restrict: 'A',
			scope: {
				data: '=xpsuiPortalWidgetListOfEventEdit',
				index: '='
			},
			require: ['^xpsuiPortalArticleContentEdit'],
			link: function(scope, elm, attrs, ctrls) {
				log.group('portal-widget-list-of-event-edit Link');

				elm.empty();
				elm.addClass('x-portal-widget-edit');

				var titleBar = angular.element('<div class="xpsui-portal-widget-title-bar">{{data.meta.type}}:{{data.meta.name}}<div class="pull-right-widget"><i class="action-button icon-chevron-up" ng-click="moveUp();"></i><i class="action-button icon-chevron-down" ng-click="moveDown();"></i><i class="action-button icon-trash" ng-click="remove();"></i></div></div>');
				var content = angular.element(
					'<form class="form">'
					+ '<fieldset class="form-group" style="background-color: black; padding: 10px;" ng-show="mode !== \'edit\'">'
					+ '<div style="background-color: white; padding-top: 10px; padding-bottom: 10px;">'
					+ '<div class="form-group row">'
					+ ' <div style="background-color: white; padding: 2px;">Tag článku, na ktorom sa nachádza widget detail udalosti: <input ng-model="data.prelinkArticleTag"/></div>'
					+ ' <div>Filtrovanie podla typu: <i class="action-button icon-add" ng-click="add();"></i></div>'
					+ ' <div ng-repeat="tpe in data.criteriumTypeArray.type" class="psui-wrapper" style="display: inline-block; padding: 0px; position: relative;">'
					+ '  <div style="background-color: white; padding: 2px;"><input ng-model="tpe.title"/><i class="action-button icon-trash" ng-click="removeType($index);"></i></div>'
					+ ' </div>'
					+ '</div>'
					+ '</div>'
					+ '</fieldset>'
					+ '</form>');
				content.append('');

				elm.append(titleBar);
				elm.append(content);

				$compile(titleBar)(scope);
				$compile(content)(scope);

				scope.moveUp = function() {
					ctrls[0].moveUp(scope.index);
				};

				scope.moveDown = function() {
					ctrls[0].moveDown(scope.index);
				};

				scope.remove = function() {
					ctrls[0].remove(scope.index);
				};

				scope.add = function() {
					scope.data.criteriumTypeArray.type.push({
					});
				};

				scope.removeType = function(idx) {
					scope.data.criteriumTypeArray.type.splice(idx, 1);
				};

				log.groupEnd();
			}
		};
	}]);

}(window.angular));