(function(angular) {
  'use strict';

  angular.module('xpsui:directives')
  .directive('xpsuiPortalWidgetMinistryfinanceView', ['xpsui:logging', '$compile', function(log, $compile) {
    return {
      restrict: 'A',
      scope: {
        data: '=xpsuiPortalWidgetMinistryfinanceView'
      },
      link: function(scope, elm, attrs, ctrls) {
        log.group('portal-widget-ministryfinance-view Link');

        elm.empty();
        elm.addClass('x-portal-widget-view');
        var target = scope.data.data.newWindow?' target="_blank" ': ' ';
        var content = angular.element(
          '<div style="text-align: center;">' +
            '<a href="http://www.minedu.sk/register-poskytnutych-dotacii/">' +
              '<img src="http://www.minedu.sk/images/logo-minedu-sk.svg"/>' +
            '</a>' +
          '</div>' +
            '<div style="text-align: center; padding-top: 5px; line-height: 1.5;">Prostriedky zo štátneho rozpočtu na šport <br> v roku {{data.data.year}}: {{data.data.value}} eur' +
            '</div>'           
          );

        elm.append(content);

        $compile(content)(scope);

        log.groupEnd();
      }
    };
  }]);

}(window.angular));