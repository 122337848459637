(function(angular) {
	'use strict';

	angular.module('xpsui:directives')
	.directive('xpsuiPortalWidgetBlogPostView', ['xpsui:logging', '$compile', function(log, $compile) {
		return {
			restrict: 'A',
			scope: {
				data: '=xpsuiPortalWidgetBlogPostView',
				index: '='
			},
			link: function(scope, elm, attrs, ctrls) {
				log.group('portal-widget-blog-post-view Link');

				elm.empty();
				elm.addClass('x-portal-widget-view');
				elm.addClass('x-portal-widget-blog-post-view');

				var content = angular.element('<div class="x-portal-widget-blog-post-view-content">' +
						'<div ng-repeat="photo in data.data.images" class="psui-wrapper x-portal-widget-blog-post-view-block" style="display: inline-block; padding: 0px; margin: 0 20px 20px 0; position: relative;">' +
								'<img ng-src="{{photo.img}}" style="width: 278px !important; height: 278px !important; background-image: url(https://localhost:3443/img/no_image.jpg); margin:0;">' +
							'</img><div class="x-portal-widget-blog-post-view-text" ng-bind="photo.text"></div>' + '<div class="x-portal-widget-blog-post-view-text" ng-bind="photo.under"></div>' + 
						'</div>' +
					'</div>');
				elm.append(content);

				$compile(content)(scope);

				log.groupEnd();
			}
		};
	}]);

}(window.angular));