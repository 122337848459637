(function(angular) {
	'use strict';
	
	function b64EncodeUnicode(str) {
		return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function(match, p1) {
			return String.fromCharCode('0x' + p1);
		}));
	}
	
	angular.module('xpsui:services')
	/**
	 * Every function call requires context. Context should contain all necessary data
	 * for action
	 *
	 * Mandatory fields are:
	 * scope - angular scope
	 * model - shortcut to model
	 * args - function arguments defined in schema
	 * local - local scope for eval
	 * All functions can return promise. Safest way how to call function is thrue
	 * invoke method.
	 */
	.factory('xpsui:StandardActions', [
		'$q', 'xpsui:Errors', '$location', 'xpsui:SchemaTools', '$interpolate', '$http', 'xpsui:NotificationFactory', 'xpsui:calculator2', '$route', '$translate',
		function(q, errors, $location, schemaTools, $interpolate, $http, notifications, calculator2, $route, $translate) {
			var tmp = {};
			var StandardActions = function() {
			};

			StandardActions.prototype.invoke = function(funcName, ctx) {
				var p = q.defer();

				if (this[funcName]) {
					var self = this;
					q.when(self[funcName].call(self, (ctx)),
						function(r) {
							if (ctx.args && ctx.args.successMsg) {
								// TODO translate
								notifications.info({text: ctx.args.successMsg, timeout: 3000});
							}
							p.resolve(r);
						}, function(r) {
							if (typeof r === 'object' && r != null && 'data' in r) {
								for (var i in r.data) {
									for (var e in r.data[i]) {
										if ('c' in r.data[i][e] && 'd' in r.data[i][e] && 'f' in r.data[i][e]) {
											notifications.warn({text: r.data[i][e].d + ': ' + $translate.instant(r.data[i][e].c), timeout: 3000});
										}
									}
								}
							}
							if (typeof r === 'object' && r != null && 'userMassageNotification' in r) {
								notifications.error({text: r.userMassageNotification, timeout: 3000});
							}

							if (ctx.args && ctx.args.errorMsg) {
								// TODO translate
								notifications.error({text: ctx.args.errorMsg, timeout: 3000});
							}
							p.reject(errors.unhandled(r));
						});

					return p.promise;
				}

				return q.reject();
			};

			StandardActions.prototype.multiSerial = function(ctx) {
				var p = q.defer();
				var actionsLeft = ctx.args.actions.slice();

				var self = this;
				function runOne(action, local) {
					if (action.func) {
						var localCtx = {
							scope: ctx.scope,
							model: ctx.model,
							form: ctx.form,
							args: action.args,
							local: local,
							tmp: tmp
						};

						self.invoke(action.func, localCtx).then(function(r) {
							if (actionsLeft.length > 0) {
								runOne(actionsLeft.shift(), r);
							} else {
								// nothing left we are done
								p.resolve(r);
							}
						}, function(r) {
							// failure
							p.reject(errors.unhandled(r));
						});
					}
				}

				runOne(actionsLeft.shift(), {});

				return p.promise;
			};

			StandardActions.prototype.set = function(ctx) {
				if (!ctx.args.value) {
					return;
				}

				var p = (ctx.args.path || '').split('.');
				var objFragment = ctx.args.obj || ctx;
				var pathFragment;
				var value = $interpolate(ctx.args.value)(ctx);

				while (p.length > 0) {
					pathFragment = p.shift();

					if (!angular.isObject(objFragment[pathFragment])) {
						objFragment[pathFragment] = {};
					}

					if (p.length === 0) {
						objFragment[pathFragment] = value;
						return;
					} else {
						objFragment = objFragment[pathFragment];
					}
				}
			};

			/**
			 * Navigates to provided path. Path is interpolated by shole context so
			 * it can work with all required fields of scope, model, args and local (return value of previous function);
			 */
			StandardActions.prototype.navigate = function(ctx) {
				var urlDef = (ctx.args && ctx.args.url) || ctx.local;
				var url = $interpolate(urlDef)(ctx);

				$location.path(url);

				return q.when(ctx.local);
			};
			
			StandardActions.prototype.navigateUrl = function(ctx) {
				var urlDef = (ctx.args && ctx.args.url) || ctx.local;
				var url = $interpolate(urlDef)(ctx);
				
				$location.url(url);
				
				return q.reject();
			};
			
			StandardActions.prototype.openTab = function(ctx) {
				var urlDef = (ctx.args && ctx.args.url) || ctx.local;
				var url = $interpolate(urlDef)(ctx);

				window.open(url, '_blank');

				return q.when(ctx.local);
			};

			/**
			 * The function downloads a file from an url address in the same browser tab. If an error occurs, the function is rejected.
			 * WARNING: For large files, the download time will be high and may fail due to a default limit.
			 * 
			 * @param {string} url - url file
			 * @param {object} params - input GET parameters for the server
			 * @param {string} fileName - name of the output file
			 * @param {string} type - the output file type for the Blob object
			 */

			StandardActions.prototype.download = function(ctx) {
				var url = $interpolate( (ctx.args && ctx.args.url) || ctx.local )(ctx);
				var params = (ctx.args && ctx.args.params) || [];
				var fileName = (ctx.args && ctx.args.fileName) || 'file';
				var type = (ctx.args && ctx.args.type);

				var p = q.defer();

				$http({
					url: url,
					method: 'GET',
					responseType: 'arraybuffer',
					params: params,
					headers: {
						'Content-type': 'application/json'
					}
				}).then(function mySucces(data) {
					var blob = new Blob([data.data], {type: type});

				    if (window.navigator.msSaveOrOpenBlob) { // For IE:
				        navigator.msSaveBlob(blob, fileName);
				    } else { // For other browsers:
				        var link = document.createElement('a');
				        link.href = window.URL.createObjectURL(blob);
				        link.download = fileName;
				        link.click();
				        window.URL.revokeObjectURL(link.href);
				    }
					return p.resolve(link);
				}, function myError(err) {
					return p.reject(err);
				})

				return p.promise;
			};

			/**
			 * Example path "./data/xxx/" is converted to "./xxx/get/".
			 */
			StandardActions.prototype.setDownloadPath = function(ctx) {
				var url = (ctx.args && ctx.args.url) || ctx.local;

				ctx.local = url.replace(/\.\/data\/([a-z]*)\//ig, './$1/get/');

				return q.when(ctx.local);
			};

			StandardActions.prototype.reload = function(ctx) {
				$route.reload();

				return q.when();
			};

			StandardActions.prototype.validateForm = function(ctx) {
				if (!ctx.form.isValid()) {
					return q.reject(false)
				} else {
					return q.when(true)
				}
			};

			/**
			 * Executes particular function defined in scope.
			 *
			 * ctx.args
			 * - func: name of function
			 */
			StandardActions.prototype.execScopeFunc = function(ctx) {
				if (!(ctx.args && ctx.args.func)) {
					return q.reject(errors.byCode(errors.INTERNAL_ERROR, {mgs: 'Function name not defined'}));
				}

				var funcName = ctx.args.func;

				if (ctx.scope && typeof ctx.scope[funcName] === 'function') {
					var p = q.defer();

					// TODO allow to pass parameters, use apply or call to invoke function #103744560
					q.when(ctx.scope[funcName]()).then(
						function(r) {
							p.resolve(r);
						},
						function(r) {
							p.reject(r);
						});
					return p.promise;
				} else {
					return q.reject(errors.byCode(errors.INTERNAL_ERROR, {mgs: 'Function does not exist'}));
				}
			};

			StandardActions.prototype.expression = function(ctx) {
				var calc = calculator2.createCalculator(ctx.args.expression);
				var calcCtx = calculator2.createCtx(ctx.scope, ctx.model, ctx.local, ctx.tmp);

				var result = calc.execute(calcCtx);

				return result;
			};

			StandardActions.prototype.wfEvent = function(ctx) {
				var p = q.defer();

				var payload = {}

				var event = $interpolate(ctx.args.event)(ctx);

				if (typeof ctx.local === "object") {
					payload = ctx.local
					if (payload && payload._id) {
						payload.id = payload._id
						delete payload._id
					}
				} else {
					payload = {id: ctx.local}
				}			

				$http({url: '/wf/event/Message/' + event, method: 'PUT', data: payload}).then(
					function(data) {
						return p.resolve(ctx.local);
					},
					function(err) {
						return p.reject(err);
					}
				);

				return p.promise
			};

			StandardActions.prototype.noneIsEmpty = function(ctx) {
				var fields = ctx.args
				
				function get(obj, path, def) {
					var p = (path || '').split('.');

					var objFragment, pathFragment;

					objFragment = obj;

					while (p.length > 0) {
						pathFragment = p.shift();

						if (angular.isObject(objFragment)) {
							objFragment = objFragment[pathFragment];
						} else {
							return def;
						}
					}

					//TODO in case objFragment is false it returns default which is wrong
					return objFragment || def;
				}

				if (fields.every(function(v) {
					var x = get(ctx.model, v, null);

					if (x === '' || x === null) {
						return false;
					}

					return true;
				})) {
					return q.when();
				} else {
					notifications.warn({text: "Nie sú vyplnené všetky povinné polia", timeout: 3000})
					return q.reject('Nie sú vyplnené všetky povinné polia');
				}
			};

			StandardActions.prototype.getBySchema = function(ctx) {
				var schema = $interpolate(ctx.args.schema)(ctx);
				var _id = ctx.args._id || ctx.local;

				var p = q.defer();

				$http({url: '/udao/getBySchema/' + schema + '/' + _id, method: 'GET'}).then(
					function(data) {

						p.resolve(data.data)
					},
					function(err) {
						return p.reject(err)
					}
				);

				return p.promise;
			}

			StandardActions.prototype.saveBySchema = function(ctx) {
				var schema = $interpolate(ctx.args.schema)(ctx);
				var p = q.defer();
				var o = ctx.local;

				// FIXME this is dirty hack that overcomes issue with id key usage in schemas
				// When schemas do not mangle ID key remove this
				if (o._id) {
					o.id = o._id;
					delete o._id;
				}

				$http({url: '/udao/saveBySchema/' + schema, method: 'PUT', data: o}).then(
					function(data) {
						return p.resolve(data.data);
					},
					function(err) {
						return p.reject(err);
					}
				);

				return p.promise;
			};

			StandardActions.prototype.searchBySchema = function(ctx) {
				var schema = $interpolate(ctx.args.schema)(ctx);
				var p = q.defer();
				var data = ctx.local;

				if (!('crits' in data)) {
					data = {crits: ctx.local};

					if ('crits' in ctx.args) {
						data['crits'] = ctx.args.crits;
					}
					if ('fields' in ctx.args) {
						data['fields'] = ctx.args.fields;
					}
					if ('sorts' in ctx.args) {
						data['sorts'] = ctx.args.sorts;
					}
					if ('limit' in ctx.args) {
						data['limit'] = ctx.args.limit;
					}
				}

				$http({url: '/search/' + schema, method: 'POST', data: data}).then(
					function(res) {
						return p.resolve(res.data);
					},
					function(err) {
						return p.reject(err);
					}
				);

				return p.promise;
			};

			StandardActions.prototype.SearchBySchemaCount = function(ctx) {
				var schema = $interpolate(ctx.args.schema)(ctx);
				var p = q.defer();
				var data = ctx.local;

				if (!('crits' in data)) {
					data = {crits: ctx.local};

					if ('crits' in ctx.args) {
						data['crits'] = ctx.args.crits;
					}
					if ('fields' in ctx.args) {
						data['fields'] = ctx.args.fields;
					}
					if ('sorts' in ctx.args) {
						data['sorts'] = ctx.args.sorts;
					}
					if ('limit' in ctx.args) {
						data['limit'] = ctx.args.limit;
					}
				}

				$http({url: '/search/fullCount/' + schema, method: 'POST', data: data}).then(
					function(res) {
						return p.resolve(res.data);
					},
					function(err) {
						return p.reject(err);
					}
				);

				return p.promise;
			};

			StandardActions.prototype.cardGenerator = function(ctx) {
				var schema = $interpolate(ctx.args.schema)(ctx);
				var p = q.defer();

				$http({url: '/udao/cardGenerator/' + schema, method: 'POST', data: ctx.local}).then(
					function(data) {
						return p.resolve(data.data);
					},
					function(err) {
						return p.reject(err);
					}
				);

				return p.promise;
			};

			StandardActions.prototype.generatePDF = function(ctx) {
				var schema = $interpolate(ctx.args.schema)(ctx);
				var template = $interpolate(ctx.args.template)(ctx);
				var p = q.defer();

				$http({url: '/cardGenerator/' + template + '/' + schema, method: 'POST', data: ctx.local}).then(
					function(data) {
						return p.resolve(data.data);
					},
					function(err) {
						return p.reject(err);
					}
				);

				return p.promise;
			};

			StandardActions.prototype.modalWindow = function(ctx) {
				var p = q.defer();
				var options = ctx.args.options || ctx.local

				var mwindow = window[Symbol.for('mbry-ui')].components.ModalWindow.Open(options);
				mwindow.addEventListener('mbry-close', function(evt) {
					p.resolve(mwindow._result)
				})

				return p.promise
			}
			
			StandardActions.prototype.createQueryFilterFromPlayerIds = function(ctx) {
				ctx.local = {
					"crits": [
						{
							"f": "player.club.oid",
							"op": "eq",
							"v": ctx.local
						}
					],
					"fields": [],
					"sorts": [{
						"f": "baseData.registrationID",
						"o": "asc"
					}],
					"limit": 1000,
					"skip": 0
				}
				return q.when(ctx.local)
			};
			
			StandardActions.prototype.exportToXml = function(ctx) {
				var schema = $interpolate(ctx.args.schema)(ctx);
				var query = b64EncodeUnicode(JSON.stringify(ctx.local, null, 3))
				ctx.local = '/api/v1.0.0/by-schema/export-xml/' + schema + '/' + query;
				return q.when(ctx.local)
			};
			
			StandardActions.prototype.exportToXlsx = function(ctx) {
				var schema = $interpolate(ctx.args.schema)(ctx);
				var query = b64EncodeUnicode(JSON.stringify(ctx.local, null, 3))
				ctx.local = '/api/v1.0.0/by-schema/export/' + schema + '/' + query;
				return q.when(ctx.local)
			};
			
			StandardActions.prototype.exportToCsv = function(ctx) {
				var schema = $interpolate(ctx.args.schema)(ctx);
				var query = b64EncodeUnicode(JSON.stringify(ctx.local, null, 3))
				ctx.local = '/api/v1.0.0/by-schema/export-csv/' + schema + '/' + query;
				return q.when(ctx.local)
			};
			
			StandardActions.prototype.exportToDat = function(ctx) {
				var schema = $interpolate(ctx.args.schema)(ctx);
				var query = b64EncodeUnicode(JSON.stringify(ctx.local, null, 3))
				ctx.local = '/api/v1.0.0/by-schema/export-dat/' + schema + '/' + query;
				return q.when(ctx.local)
			};

			/**
			 * THIS IS NASTY QUICK HACK
			 */
			StandardActions.prototype.svfHack = function(ctx) {
				var p = q.defer();

				function get(obj, path, def) {
					var p = (path || '').split('.');

					var objFragment, pathFragment;

					objFragment = obj;

					while (p.length > 0) {
						pathFragment = p.shift();

						if (angular.isObject(objFragment)) {
							objFragment = objFragment[pathFragment];
						} else {
							return def;
						}
					}

					//TODO in case objFragment is false it returns default which is wrong
					return objFragment || def;
				}

				function set(obj, path, value) {
					if (!value) {
						return;
					}

					var p = (path || '').split('.');

					var objFragment, pathFragment;

					objFragment = obj;

					while (p.length > 0) {
						pathFragment = p.shift();

						if (!angular.isObject(objFragment[pathFragment])) {
							objFragment[pathFragment] = {};
						}

						if (p.length === 0) {
							objFragment[pathFragment] = value;
							return;
						} else {
							objFragment = objFragment[pathFragment];
						}
					}
				}

				var o = {};
				var lp;

				lp = 'baseData.id'; set(o, lp, get(ctx.model, lp, null));
				lp = 'baseData.name'; set(o, lp, get(ctx.model, lp, null));
				lp = 'baseData.surName'; set(o, lp, get(ctx.model, lp, null));
				lp = 'baseData.titleAfter'; set(o, lp, get(ctx.model, lp, null));
				lp = 'baseData.birthDate'; set(o, lp, get(ctx.model, lp, null));
				lp = 'baseData.birthDate'; set(o, lp, get(ctx.model, lp, null));
				lp = 'baseData.gender'; set(o, lp, get(ctx.model, lp, null));
				lp = 'baseData.nationality'; set(o, lp, get(ctx.model, lp, null));
				lp = 'player.isPlayer'; set(o, lp, get(ctx.model, lp, null));
				lp = 'player.stateOfPlayer'; set(o, lp, get(ctx.model, lp, null));
				lp = 'player.registrationCanceled'; set(o, lp, get(ctx.model, lp, null));
				lp = 'player.validFrom'; set(o, lp, get(ctx.model, lp, null));
				lp = 'player.validTo'; set(o, lp, get(ctx.model, lp, null));
				lp = 'officer.isOfficer'; set(o, lp, get(ctx.model, lp, null));
				lp = 'officer.stateOfOfficer'; set(o, lp, get(ctx.model, lp, null));
				lp = 'officer.association'; set(o, lp, get(ctx.model, lp, null));
				lp = 'officer.club'; set(o, lp, get(ctx.model, lp, null));
				lp = 'officer.note'; set(o, lp, get(ctx.model, lp, null));
				lp = 'officer.dateOfRegistration'; set(o, lp, get(ctx.model, lp, null));
				lp = 'officer.expiration'; set(o, lp, get(ctx.model, lp, null));
				lp = 'coach.isCoach'; set(o, lp, get(ctx.model, lp, null));
				lp = 'coach.stateOfCoach'; set(o, lp, get(ctx.model, lp, null));
				lp = 'coach.proffesionalCompetence'; set(o, lp, get(ctx.model, lp, null));
				lp = 'coach.dateOfRegistration'; set(o, lp, get(ctx.model, lp, null));
				lp = 'coach.coachLicense'; set(o, lp, get(ctx.model, lp, null));
				lp = 'coach.coachLicenseLevel'; set(o, lp, get(ctx.model, lp, null));
				lp = 'coach.coachLicenseType'; set(o, lp, get(ctx.model, lp, null));
				lp = 'coach.licenseSeminar'; set(o, lp, get(ctx.model, lp, null));
				lp = 'medic.isMedic'; set(o, lp, get(ctx.model, lp, null));
				lp = 'medic.medicLicense'; set(o, lp, get(ctx.model, lp, null));
				lp = 'medic.stateOfMedic'; set(o, lp, get(ctx.model, lp, null));
				lp = 'medic.dateOfRegistration'; set(o, lp, get(ctx.model, lp, null));
				lp = 'medic.validFrom'; set(o, lp, get(ctx.model, lp, null));
				lp = 'medic.validTo'; set(o, lp, get(ctx.model, lp, null));
				lp = 'statistic.isStatistic'; set(o, lp, get(ctx.model, lp, null));
				lp = 'statistic.statisticLicense'; set(o, lp, get(ctx.model, lp, null));
				lp = 'statistic.stateOfStatistic'; set(o, lp, get(ctx.model, lp, null));
				lp = 'statistic.dateOfRegistration'; set(o, lp, get(ctx.model, lp, null));
				lp = 'statistic.validFrom'; set(o, lp, get(ctx.model, lp, null));
				lp = 'statistic.validTo'; set(o, lp, get(ctx.model, lp, null));
				lp = 'scorer.isScorer'; set(o, lp, get(ctx.model, lp, null));
				lp = 'scorer.scorerLicense'; set(o, lp, get(ctx.model, lp, null));
				lp = 'scorer.stateOfScorer'; set(o, lp, get(ctx.model, lp, null));
				lp = 'scorer.club'; set(o, lp, get(ctx.model, lp, null));
				lp = 'scorer.dateOfRegistration'; set(o, lp, get(ctx.model, lp, null));
				lp = 'scorer.validFrom'; set(o, lp, get(ctx.model, lp, null));
				lp = 'photoInfo.photo'; set(o, lp, get(ctx.model, lp, null));
				set(o, 'id', get(ctx.model, 'peopleObjLink.people.oid', null));

				if (!o.id) {
					notifications.warn({text: 'Nepodarilo sa upraviť záznam, nie je nastavená osoba', timeout: 3000});
					return q.reject();
				}
				$http({url: '/udao/saveBySchema/uri~3A~2F~2Fregistries~2Fpeople~23views~2Ffullperson~2Fview', method: 'PUT', data: o}).then(
					function(data) {
						notifications.info({text: 'Záznam osoby upravený', timeout: 3000});
						return p.resolve(data);
					},
					function(err) {
						notifications.warn({text: 'Nepodarilo sa upraviť záznam, vykonajte manuálnu úpravu', timeout: 3000});
						return p.reject(err);
					}
				);

				return p.promise;
			};

			return new StandardActions();
		}]);
}(window.angular));
