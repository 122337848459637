(function(angular) {
	'use strict';

	angular.module('xpsui:directives')
	.directive('xpsuiPortalWidgetRefHgalleryView', ['xpsui:logging', '$compile', function(log, $compile) {
		return {
			restrict: 'A',
			scope: {
				data: '=xpsuiPortalWidgetRefHgalleryView',
				index: '='
			},
			link: function(scope, elm, attrs, ctrls) {
				log.group('portal-widget-ref-hgallery-view Link');

				elm.empty();
				elm.addClass('x-portal-widget-view');
				elm.addClass('x-portal-widget-ref-hgallery-view');

				var content = angular.element('<div class="x-portal-widget-ref-hgallery-view-content">' +
						'<div ng-repeat="photo in data.data.images" class="psui-wrapper x-portal-widget-ref-hgallery-view-block" style="display: inline-block; padding: 0px; margin: 0 20px 20px 0; position: relative;">' +
								'<img ng-src="{{photo.img}}" style="width: 291px !important; height: 194px !important; background-image: url(https://localhost:3443/img/no_image.jpg); margin:0;">' +
							'</img><div class="x-portal-widget-ref-hgallery-view-text" ng-bind="photo.text"></div>' +
						'</div>' +
					'</div>');
				elm.append(content);

				$compile(content)(scope);

				log.groupEnd();
			}
		};
	}]);

}(window.angular));


