(function(angular) {
	'use strict';

	angular.module('xpsui:directives')

	/**
	 * Renders and manages block of form components. Mainly used for schema render.
	 *
	 * @module client
	 * @submodule directives
	 * @class xpsui-fieldset
	 */
	.directive('xpsuiFieldset', ['xpsui:logging', 'xpsui:FormGenerator', '$translate', '$compile', 
		function(log, formGenerator, $translate, $compile) {
			return {
				restrict: 'A',
				require: ['?^xpsuiForm'],
				link: function(scope, elm, attrs, ctrls) {
					log.group('FieldSet Link');

					var i;

					/**
					 * NgModel binding for fieldset
					 * @default false
					 * @required true
					 * @attribute xpsui-model
					 */
					var modelPath = attrs.xpsuiModel;

					/**
					 * Options used for directive processing. It can consume schema fragment
					 * @default false
					 * @required true
					 * @attribute xpsui-options
					 */
					var options = attrs.xpsuiOptions;

					elm.addClass('x-fieldset');
					var opt = scope.$eval(attrs.xpsuiOptions)
					elm.addClass('x-fieldset');

					var form = ctrls[0];
					
					if (form && opt.calculatedVisibility) {
						var unregister2 = form.registerCalculation2(opt.calculatedVisibility, function(err, val) {
							// FIXME do something with error
							//schemaFragment.crits = val;
							if (err) {
								log.error(err);
							} else {
								if (val) {
									elm.removeClass('ng-hide');
								} else {
									elm.addClass('ng-hide');
								}
							}
						});
						scope.$on('destroy', unregister2);
					}

					if (modelPath && options) {
					} else {
						log.warn('Attributes xpsui-model and xpsui-options have to be set, skipping fieldset generation');
						log.groupEnd();
						return;
					}

					var schema = scope.$eval(options);
					var mode = attrs.xpsuiFieldset;

					if (schema) {
						/**
						 * full screen render
						 */
						if (schema.render && schema.render.width) {
							switch (schema.render.width) {
							case 'full': 
								elm.addClass('x-fieldset-full');
								break;
							}
						}

						if (schema.render && schema.render.style && schema.render.style === 'flex-row') {
							// render as flex-row
							elm.addClass('x-fieldset-flex-row');

							if (schema.render.blockTitle) {
								elm.append('<div class="x-fieldset-title">' 
									+ (schema.render.blockTitle.transCode ? $translate.instant(schema.transCode) : schema.render.blockTitle)
									+ '</div>'
								);
							}

							var header = angular.element('<div class="x-fieldset-flex-row-header"></div>');
							for (i in schema.render.headers) {
								header.append('<div class="x-fieldset-flex-row-title-header">'
									+(schema.render.headers[i].transCode ? $translate.instant(schema.render.headers[i].transCode) : schema.render.headers[i])+'</div>');
							}
							elm.append(header);


							var content = angular.element('<div class="x-fieldset-flex-row-content"></div>');
							if (schema && (schema.title || schema.transCode)) {
								// TODO translation
								content.append('<div class="x-fieldset-flex-row-content-column">' 
									+ (schema.transCode ? $translate.instant(schema.transCode) : schema.title)
									+ '</div>'
								);

							} else {
								content.append('<div class="x-fieldset-flex-row-content-column"></div>');
							}

							for (i in schema.properties) {
								var col = angular.element('<div class="x-fieldset-flex-row-content-column"></div>');
								col.append(formGenerator.generateField(schema.properties[i], options+'.properties.' + i, modelPath + '.' + i, mode || firmGenertor.MODE.VIEW));

								content.append(col);
							}

							elm.append(content);
							$compile(content)(scope);
							log.groupEnd();
							return;
						}
					}

					if (schema && (schema.title || schema.transCode)) {
						// TODO translation
						elm.append('<div class="x-fieldset-title">' 
							+ (schema.transCode ? $translate.instant(schema.transCode) : schema.title)
							+ '</div>'
						);

					} else {
						elm.append('<div class="x-fieldset-title"></div>');
					}

					var content = angular.element('<div class="x-fieldset-content"></div>');
					elm.append(content);
					formGenerator.generateForm(scope, content, schema, options, modelPath, mode || formGenerator.MODE.VIEW);

					log.groupEnd();
				}
			};
		}
	]);

}(window.angular));
