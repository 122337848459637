(function(angular) {
	'use strict';
	angular.module('xpsui:directives')
	.directive('xpsuiPortalWidgetPollEdit', ['xpsui:logging', '$compile', function(log, $compile) {
		return {
			restrict: 'A',
			scope: {
				data: '=xpsuiPortalWidgetPollEdit',
				index: '='
			},
			require: ['^xpsuiPortalArticleContentEdit'],
			link: function(scope, elm, attrs, ctrls) {
				log.group('portal-widget-poll-edit Link');

				elm.empty();
				elm.addClass('x-portal-widget-edit');
				elm.addClass('x-portal-widget-poll-edit');

				scope.addSubBlockMenuVisible = false;

				var menuTemplate = '<div class="x-portal-widget-poll-edit-add-sub-blok-menu" ng-show="addSubBlockMenuVisible">' +
					'<div ng-click="addSubBlock(\'heading-1\')">Nadpis</div>' +
					'<div ng-click="addSubBlock(\'heading-2\')">Podnadpis</div>' +
					'<div ng-click="addSubBlock(\'text\')">Text</div>' +
					'<div ng-click="addSubBlock(\'answers\')">Odpovede</div>' +
				'</div>';

				var titleBar = angular.element('<div class="xpsui-portal-widget-title-bar">{{data.meta.type}}:{{data.meta.name}}<div class="pull-right-widget"><i class="action-button icon-add" style="position: relative;" ng-click="add();">'+ menuTemplate +'</i><i class="action-button icon-chevron-up" ng-click="moveUp();"></i><i class="action-button icon-chevron-down" ng-click="moveDown();"></i><i class="action-button icon-trash" ng-click="remove();"></i></div></div>');
	//				var content = angular.element('<div class="psui-wrapper" style="text-align: center;"><psui-uploadable-image psui-imageresizor="" psui-imageresizor-width="656" psui-imageresizor-height="492" ng-model="data.data.img" style="width: 656px !important; height: 492px !important; background-image: url(https://localhost:3443/img/no_image.jpg); margin:0;"></psui-uploadable-image></div>');

				var content = angular.element(
					'<div>' +
						'<div ng-repeat="block in data.data.blocks" class="x-portal-widget-poll-edit-block-wrapper">' +
							'<div class="x-portal-widget-poll-edit-block">' +
								'<div class="x-portal-widget-poll-edit-block-menu"><i ng-if="block.type === \'answers\'" class="icon-add" ng-click="addAnswer($index)"></i><i class="icon-chevron-up" ng-click="moveBlockUp($index)"></i><i class="icon-chevron-down" ng-click="moveBlockDown($index)"></i><i class="icon-trash" ng-click="removeBlock($index)"></i></div>' +
								'<div ng-switch on="block.type">' +
									'<div ng-switch-when="heading-1" class="x-portal-widget-poll-edit-block-heading-1">' +
										'<input type="text" ng-model="block.text">' +
									'</div>' +
									'<div ng-switch-when="heading-2" class="x-portal-widget-poll-edit-block-heading-2">' +
										'<input type="text" ng-model="block.text">' +
									'</div>' +
									'<div ng-switch-when="text" class="x-portal-widget-poll-edit-block-text">' +
										'<div xpsui-contenteditable="true" ng-model="block.text"></div>' +
									'</div>' +
									'<div ng-switch-when="answers" class="x-portal-widget-poll-edit-block-answers">' +
										'<div ng-repeat="answer in block.answers" class="x-portal-widget-poll-edit-block-answers-answer">' +
											'<div class="answer-photo" style="width: {{$parent.data.config.profilePhoto.width}}px; height: {{$parent.data.config.profilePhoto.height}}px">' +
												'<div xpsui-uploadable-image xpsui-imageresizor psui-width="{{$parent.data.config.profilePhoto.width}}" psui-height="{{$parent.data.config.profilePhoto.height}}" ng-model="answer.image"></div>' +
											'</div>' +
											'<div class="answer-desc">' +
												'<div><input type="text" class="name" ng-model="answer.name"></input></div>' +
												'<div><input type="text" class="surname" ng-model="answer.surname"></input></div>' +
												'<div><input type="text" class="club" ng-model="answer.club"></input></div>' +
											'</div>' +
										'</div>' +
									'</div>' +
								'</div' +
							'</div>' +
						'</div>' +
					'</div>'
				);

				elm.append(titleBar);
				elm.append(content);

				$compile(titleBar)(scope);
				$compile(content)(scope);

				scope.moveUp = function() {
					ctrls[0].moveUp(scope.index);
				};

				scope.moveDown = function() {
					ctrls[0].moveDown(scope.index);
				};

				scope.remove = function() {
					ctrls[0].remove(scope.index);
				};

				scope.add = function() {
					scope.addSubBlockMenuVisible = !scope.addSubBlockMenuVisible;
				};

				scope.moveBlockUp = function(idx) {
					if (idx > 0) {
						var tmpBlk = scope.data.data.blocks[idx];
						scope.data.data.blocks[idx] = scope.data.data.blocks[idx-1];
						scope.data.data.blocks[idx-1] = tmpBlk; 
					}
				};

				scope.moveBlockDown = function(idx) {
					if (idx < scope.data.data.blocks.length) {
						var tmpBlk = scope.data.data.blocks[idx];
						scope.data.data.blocks[idx] = scope.data.data.blocks[idx+1];
						scope.data.data.blocks[idx+1] = tmpBlk; 
					}
				};

				scope.removeBlock = function(idx) {
					scope.data.data.blocks.splice(idx, 1);
				};

				scope.addAnswer = function(idx) {
					var vid = new Date().getTime().toString(16);

					for (var i=0; i<16; ++i) {
						vid += Math.floor(Math.random() * 16).toString(16);
					}

					scope.data.data.blocks[idx].answers.push({
						name: 'Meno',
						surname: 'Priezvisko',
						club: 'Klub',
						voteId: vid});
				};

				scope.photoLeft = function(idx) {
					if (idx > 0) {
						var tmp = scope.data.data.images[idx];
					
						scope.data.data.images.splice(idx, 1);
						scope.data.data.images.splice(idx-1, 0, tmp);
					}
				};

				scope.photoRight = function(idx) {
					if (idx < scope.data.data.images.length - 1) {
						var tmp = scope.data.data.images[idx];
					
						scope.data.data.images.splice(idx, 1);
						scope.data.data.images.splice(idx+1, 0, tmp);
					}
				};

				scope.photoRemove = function(idx) {
					scope.data.data.images.splice(idx, 1);
				};

				scope.addSubBlock = function(type) {
					switch (type) {
					case 'heading-1':
						scope.data.data.blocks.push({type: 'heading-1', text: 'Nadpis'});
						break;
					case 'heading-2':
						scope.data.data.blocks.push({type: 'heading-2', text: 'Podnadpis'});
						break;
					case 'text':
						scope.data.data.blocks.push({type: 'text', text: 'Text'});
						break;
					case 'answers':
						scope.data.data.blocks.push({type: 'answers', answers: []});
						scope.addAnswer(scope.data.data.blocks.length-1);
						break;
					}
				};

				log.groupEnd();
			}
		};
	}]);

}(window.angular));
