(function(angular) {
    'use strict';

    angular.module('xpsui:directives')
    .directive('xpsuiPortalWidgetUserDetailView', ['xpsui:logging', '$compile', '$sce', function(log, $compile, $sce) {
        return {
            restrict: 'A',
            scope: {
                data: '=xpsuiPortalWidgetUserDetailView'
            },
            link: function(scope, elm, attrs, ctrls) {
                log.group('portal-widget-user-detail-view Link');

                elm.empty();
                elm.addClass('x-portal-widget-view');

                var content = angular.element('<div class="x-portal-widget-match-results">' +
                    '<section class="x-portal-competition-matches">' +
                    '<header>{{data.meta.title}}</header>' +
                    '</section>' +
                    '</div>');
                
                elm.append(content);

                $compile(content)(scope);

                log.groupEnd();

                scope.makeSafe = function(str) {
                    if (typeof str === 'string') {
                        return $sce.trustAsHtml(str);
                    }

                    return '';
                };

            }
        };
    }]);

}(window.angular));

