(function(angular) {
	'use strict';

	angular.module('xpsui:directives')
	.directive('xpsuiAction', [
		'xpsui:StandardActions',
		'$compile',
		'$translate',
		function(sAct, $compile, $translate) {
			return {
				restrict: 'E',
				require: ['?^xpsuiForm'],
				link: function(scope, elm, attrs, ctrls) {
					var options = scope.$eval(attrs.options) || {};

					var scopePrefix = attrs.scopePrefix || null;
					var modelPrefix = attrs.modelPrefix || null;

					var form = ctrls[0];

					if (form && options.calculatedVisibility) {
						var unregister2 = form.registerCalculation2(options.calculatedVisibility, function(err, val) {
							// FIXME do something with error
							//schemaFragment.crits = val;
							if (err) {
								log.error(err);
							} else {
								if (val) {
									elm.removeClass('ng-hide');
								} else {
									elm.addClass('ng-hide');
								}
							}
						});
						scope.$on('destroy', unregister2);
					}

					elm.addClass('x-action');

					if (options.withSpacer) {
						elm.addClass('x-action-with-spacer');
					}

					var btn = angular.element('<button type="button" class="btn"></button>');

					var icon = angular.element('<i></i>');
					var iconSpinner = angular.element('<i class="fa fa-spinner fa-spin"></i>');

					if (options.iconClasses) {
						icon.addClass(options.iconClasses);
					}

					btn.append(icon);
					btn.append(iconSpinner);

					// set title, classes and other stuff
					btn.append($translate.instant(options.title));

					if (options.classes) {
						btn.addClass(options.classes);
					}

					$compile(btn)(scope);
					elm.append(btn);

					iconSpinner.addClass('ng-hide');

					function stopExecution() {
						icon.removeClass('ng-hide');
						iconSpinner.addClass('ng-hide');
						btn.removeAttr('disabled');
					}

					function startExecution() {
						if (options && options.func) {

							var localScope, localModel;
							if (scopePrefix) {
								localScope = scope.$eval(scopePrefix);
							} else {
								localScope = scope;
							}

							if (modelPrefix) {
								localModel = localScope.$eval(modelPrefix);
							} else {
								localModel = localScope;
							}

							var ctx = {
								scope: localScope,
								model: localModel,
								args: options.args,
								form: form
							};

							icon.addClass('ng-hide');
							iconSpinner.removeClass('ng-hide');
							btn.attr('disabled', 'disabled');

							sAct.invoke(options.func, ctx).finally(function() {
								stopExecution();
							});
						}
					}

					btn.on('click', function() {
						scope.$apply(function() {
							startExecution();
						});
					});
				}
			};
		}]);

}(window.angular));

