(function(angular) {
	'use strict';

	angular.module('xpsui:directives')
	.directive('xpsuiDashboardWidgetCounter', [
		'$q', 'xpsui:logging', '$compile', '$translate', '$http', '$location', 'xpsui:SchemaUtil', 'xpsui:NotificationFactory',
		'xpsui:NavigationService', 'xpsui:StandardActions',
		function(q, log, $compile, $translate, $http, $location, schemaUtilFactory, notificationFactory,
				navigationService, standardActions) {
			return {
				require: ['^^xpsuiDashboardWidgetRequests'],
				template:
					'<div class="dashboard-widget-block-title">{{title}}</div>' +
					'<div class="dashboard-widget-block-content">' +
					'	<div>{{label_loading}}</div>' +
					'</div>',
				link: function(scope, elm, attrs, ctrlContr) {	
					var ctrlWidget = ctrlContr[0].scope.widgets[parseInt(attrs.xpsuiDashboardWidgetCounter)];
					var requestScope = ctrlContr[0].scope;
					var widget = ctrlWidget.widget;

					var startExecution = function(options) {
						if (options && options.func) {
							var ctx = {
								scope: scope,
								model: scope.$parent.security.currentUser,
								args: options.args,
								local: {}
							};

							return standardActions.invoke(options.func, ctx);
						} else {
							return Promise.resolve(options);
						}
					};

					var navigationExpr = ctrlWidget.widget.navigationExpr;

					scope.dashboardOnClick = function() {
						startExecution(navigationExpr)
						.then(function(navigation) {
							navigationService.navigateToPath(navigation, 'search');
							$location.path(navigation);
							scope.$apply();
						});
					};

					scope.title = $translate.instant(ctrlWidget.widget.transCode || ctrlWidget.widget.title);
					scope.label_loading = ctrlContr[0].scope.label_loading;

					startExecution(ctrlWidget.widget.dataExpr).then(function(data) {
						if (data) {
							angular.element(elm[0].querySelector('.dashboard-widget-block-content')).html(
								'<span>' + data + '</span><br/>'
								+ ' <a ng-click="' + 'dashboardOnClick()">' + $translate.instant('generic.more') + '</a>'
							);
							$compile(elm[0].querySelector('.dashboard-widget-block-content'))(scope);
						} else {
							angular.element(elm[0].querySelector('.dashboard-widget-block-content')).text(
								$translate.instant('dashboard.widget.members.noOpenRequests')
							);
						}
					});

				}
			};
		}
	]);

}(window.angular));
