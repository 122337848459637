(function(angular) {
  'use strict';

  angular.module('xpsui:directives')
  .directive('xpsuiArrayUnique', ['$http', 'xpsui:SchemaUtil', 'xpsui:logging', 'xpsui:FormGenerator', '$compile', 'xpsui:QueryFilter', function($http, schemaUtilFactory, log, formGenerator, $compile, QueryFilter) {
    var latestId = 0;

    return { 
        restrict: 'A',
        require: ['?ngModel'],

        link: function(scope, element, attrs, controllers, elm) {
	        var ngModelCtrl = controllers[0];
	        scope.ngModelController = controllers[0];
	        var duplicate = scope.$eval(attrs.xpsuiArrayUnique);

	        scope.$watchCollection(function(scope) {
	          validation()
	          return scope.$eval(attrs.ngModel)
	        }, function(newVal, oldVal){
	          // validation();
	        }, true);

	        function validation() {
		        if (ngModelCtrl) {
		            ngModelCtrl.$setDirty();

	                var value = _.cloneDeep(ngModelCtrl.$modelValue || ngModelCtrl.$viewValue);

	                if (value) {
		                var uniqiueArray = [];

		                for (var q = 0; q < value.length; q++) {
			                delete value[q].$$hashKey;

			                for (var k in value[q]) {

			                	if (value[q][k] == "" || value[q][k] == null || value[q][k] == undefined) {
			                		delete value[q][k];
			                	}
			                }

		                	uniqiueArray.push(value[q]);
		                }

		                var D = _.uniqWith(uniqiueArray, _.isEqual);

		                for (var e = 0; e < value.length; ++e)  {

			                var duplicate = _.uniq(value).length !== D.length;

			                if (duplicate == true) {
			                  return ngModelCtrl.$setValidity('xpsuiArrayUnique', false);
			                }

		                }
	                	return ngModelCtrl.$setValidity('xpsuiArrayUnique', true);
	                }
		        }
	        }; 

        }
    };
  }]);
}(window.angular));