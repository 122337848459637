(function(angular) {
	'use strict';

	angular.module('xpsui:directives')
	.directive('xpsuiPortalWidgetSocialShareView', ['xpsui:logging', '$compile', '$sce', function(log, $compile, $sce) {
		return {
			restrict: 'A',
			scope: {
				data: '=xpsuiPortalWidgetSocialShareView'
			},
			link: function(scope, elm, attrs, ctrls) {
				log.group('portal-widget-social-share-view Link');

				elm.empty();
				elm.addClass('x-portal-widget-view');

				var content = angular.element(
					'<div class="block_social_share">'
					+ '		<div ng-show="data.config.facebook" class="share-button fb share-facebook">'
					+ '		  <a href="javascript:void(0);"><i class="fa fa-facebook"></i></a>'
					+ '		</div>'
					+ '		<div ng-show="data.config.twitter" class="share-button tw share-twitter" share-text="Check out SocialShare" share-hashtags="socialshare, tooling" share-via="emailnitram">'
					+ '		  <a href="javascript:void(0);"><i class="fa fa-twitter"></i></a>'
					+ '		</div>'
					+ '		<div ng-show="data.config.linkedin" class="share-button li share-linkedin" share-title="Check out SocialShare" share-summary="Create social network sharer URLs with ease. Thanks for checking out SocialShare!">'
					+ '		  <a href="javascript:void(0);"><i class="fa fa-linkedin"></i></a>'
					+ '		</div>'
					+ '		<div ng-show="data.config.googleplus" class="share-button gp share-googleplus">'
					+ '		  <a href="javascript:void(0);"><i class="fa fa-google-plus"></i></a>'
					+ '		</div>'
					+ '</div>');

				elm.append(content);

				$compile(content)(scope);

				log.groupEnd();

			}
		};
	}]);

}(window.angular));

