/*
 *	Example for using in schema:
 *
 *   "id":{
 * 		"validityExpression": {
 *			"watch": [],
 * 			"expressionOld": {
 * 				"func": "expression",
 * 				"args": {
 * 					"expression": {
 * 						"func": "regExpressionExec",
 * 						"args": {
 * 							"pattern": "^[0-9]{2}[0123][0-9][01][0-9]\/?[0-9]{3,4}$",
 * 							"flag": "",
 * 							"text": "@@model.baseData.id"
 * 						}
 * 					}
 * 				}
 * 			}
 * 		}
 *   }
 */

(function(angular) {
	'use strict';

	angular.module('xpsui:directives')
	.directive('xpsuiValidityExpression', ['$http', 'xpsui:StandardActions', function($http, standardActions) {

		return {
			restrict: 'A',
			require: ['?ngModel'],
			link: function(scope, element, attrs, controllers) {
				var ngModel = controllers[0];
				var validityExpression = scope.$eval(attrs.xpsuiSchema).validityExpression;
				var scopePrefix = attrs.scopePrefix || null;
				var modelPrefix = attrs.modelPrefix || null;
				var form = controllers[0];

				var startExecution = function(expression) {
					if (expression && expression.func) {
						var ctx = {
							scope: scope,
							model: scope.model.obj,
							form: form,
							user: scope.$parent.security.currentUser,
							args: expression.args,
							local: {}
						};

						return standardActions.invoke(expression.func, ctx);
					} else {
						return Promise.resolve(true);
					}
				};

				function factory(data) {
					if (data) {
						ngModel.$setValidity('xpsuiFormatPattern', true);
					} else {
						ngModel.$setValidity('xpsuiFormatPattern', false);
					}
				}

				for (var i in validityExpression.watch || []) {
					scope.$watch('model.obj.' + validityExpression.watch[i], function(newValue, oldValue) {
						startExecution(validityExpression.expressionOld).then(function(data) { factory(data); });
					});
				}
				
			}
		};
	}]);
}(window.angular));
