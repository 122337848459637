(function(angular) {
	'use strict';

	angular.module('xpsui:controllers')
	.controller('xpsui:DashboardCtrl', [
		'$scope', 
		'$location', 
		function($scope, $location) {
		}
	]);
}(window.angular));