(function(angular) {
	'use strict';

	angular.module('xpsui:directives')
	.directive('xpsuiPortalWidgetRefBodyView', ['xpsui:logging', '$compile', '$sce', function(log, $compile, $sce) {
		return {
			restrict: 'A',
			scope: {
				data: '=xpsuiPortalWidgetRefBodyView',
				index: '='
			},
			link: function(scope, elm, attrs, ctrls) {
				log.group('portal-widget-ref-body-edit Link');

				elm.empty();
				elm.addClass('x-portal-widget-view');
				elm.addClass('x-portal-widget-ref-body-view');

				var img = angular.element('<img ng-src="{{data.data.img}}" class="x-portal-widget-ref-body-view-img" style="width: 167px !important;height: 168px !important;margin: 5px;float: left;"></img>');
				var editor = angular.element('<div class="x-portal-widget-ref-body-view-editor" ng-bind-html="makeSafe(data.data.body);"></div><div style="clear:both; height: 10px;"></div>');

				elm.append(img);
				elm.append(editor);

				$compile(img)(scope);
				$compile(editor)(scope);

				scope.makeSafe = function(str) {
					if (typeof str === 'string') {
						return $sce.trustAsHtml(str);
					}

					return '';
				};
				log.groupEnd();
			}
		};
	}]);

}(window.angular));


