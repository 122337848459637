/*
 *	Example for using in schema:
 *
 *   "id":{
 *      "unique": {
 *         "schema": "uri://registries/people#views/fullperson-km/view",
 *         "field": "baseData.id",
 *         "ver": 2
 *      }
 *   }
 */

(function(angular) {
	'use strict';

	angular.module('xpsui:directives')
	.directive('xpsuiUnique', ['$http', '$q', 'xpsui:SchemaUtil', 'xpsui:QueryFilter',
			function($http, $q, schemaUtilFactory, QueryFilter) {
		var latestId = 0;

		return {
			restrict: 'A',
			require: ['?ngModel'],
			link: function(scope, element, attrs, controllers) {
				var ngModel = controllers[0];
				var options = scope.$eval(attrs.xpsuiUnique);

				ngModel.$asyncValidators.psuiUnique = function (newVal, viewValue) {
					return $q(function (resolve, reject) {
						var ids = containsEqualId(attrs.ngModel);

						var qf = QueryFilter.create().addCriterium(
							options.field,
							QueryFilter.operation.EQUAL,
							newVal
						).setLimit(1);

						for (var i in ids) {
							qf.addCriterium(
								'id',
								QueryFilter.operation.NOT_EQUAL,
								ids[i]
							);
						}

						var schema = schemaUtilFactory.concatUri(options.schema, 'search');

						if (options.ver == 2) {
							schema = options.schema;
						}

						var conf = {
							method : 'POST',
							url : '/search/fullCount/' + schemaUtilFactory.encodeUri(schema),
							data : qf
						};

						function containsEqualId(ngModelStr, ids){
							if (!ids) ids = [];
							var model = scope.$eval(ngModelStr);
							
							if(typeof model === 'object' && model.id && model.id !== newVal){
								ids.push(model.id);
							}

							var pos = ngModelStr.lastIndexOf(".");
							if(pos !== -1){
								ids = containsEqualId(ngModelStr.substr(0,pos), ids);
							}

							return ids;
						}

						function factory(ver) {
							return function(data) {
								if (ver !== latestId) {
									// another validation in progress
									return;
								}

								if (data.data && data.data > 0) {
									return reject();
								} else {
									return resolve();
								}
							};
						}

						return $http(conf).then(factory(++latestId));
			 	   });
			   	};
			}
		};
	}]);
}(window.angular));
