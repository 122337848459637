(function(angular) {
	'use strict';

	angular.module('xpsui:controllers')
	/**
	* Controller used to handele Forgotten Password Page.
	* Page Form reads user email and recaptcha.
	* <br> Controller does:
	* <li> reads Captcha Site Key
	* <li> recreates captcha compoment
	* <li> handles submit to send captha and email address
	* <li> handles potential backend validation  errors
	* @method security.forgottenCtrl
	*/
	.controller('xpsui:SecurityForgottenCtrl', [ '$scope', 'xpsui:SecurityService', '$rootScope', '$location','$timeout','xpsui:NotificationFactory','$window', function($scope, SecurityService, $rootScope, $location,$timeout,notificationFactory,$window,reCAPTCHA) {
		$scope.email = '';
		$scope.identification = '';
		$scope.capcha = '';
		$scope.done=false;

		$scope.submit=function() {
			SecurityService.getCaptchaKey().success(function(json) {
				grecaptcha.ready(function() {
					grecaptcha.execute(json.key, {action: 'forgotten'}).then(function(token) {
						SecurityService.getForgotenToken($scope.email, $scope.identification, token).success(function(data) {
							var mes = {translationCode:'security.forgotten.token.generated',time: 5000};
							
							notificationFactory.info(mes);
							$scope.done=true;
						
							$timeout(function() {$location.path('/'); },5000);
						}).error(function(err,data) {
							var mes = {translationCode:err.code,translationData:err.data,time:6000};
							notificationFactory.error(mes);
							// $window.Recaptcha.reload();
						});
					});
				});
			}).error(
				function(err,data) {
					var mes = {translationCode:err.code,translationData:err.data,time:3000};
					notificationFactory.error(mes);
				}
			);
		};
	} ]);
}(window.angular));
