(function(angular) {
	'use strict';

	angular.module('xpsui:directives')
	.directive('xpsuiPortalWidgetTwoVideosInRowView', ['xpsui:logging', '$compile', function(log, $compile) {
		return {
			restrict: 'A',
			scope: {
				data: '=xpsuiPortalWidgetTwoVideosInRowView'
			},
			link: function(scope, elm, attrs, ctrls) {
				log.group('portal-widget-twovideosinrow-view Link');

				elm.empty();
				elm.addClass('x-portal-widget-view');

				var content = angular.element(
						'<div class="psui-wrapper" style="text-align: center;">'
						+ '  <p>video 1 =' + scope.data.data.src + '  </p>'
						+ '  <p>video 2 = ' + scope.data.data.src2 + '  </p>'
						+ '  <p>img1 =' + scope.data.data.img + '  </p>'
						+ '  <p>img2 = ' + scope.data.data.img2 + '  </p>'
						+ '</div>');

				elm.append(content);

				$compile(content)(scope);

				log.groupEnd();
			}
		};
	}]);

}(window.angular));


