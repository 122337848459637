(function(angular) {
	'use strict';

	angular.module('xpsui:directives')
	.directive('xpsuiPortalWidgetTwoVideosInRowEdit', ['xpsui:logging', '$compile', function(log, $compile) {
		return {
			restrict: 'A',
			scope: {
				data: '=xpsuiPortalWidgetTwoVideosInRowEdit',
				index: '='
			},
			require: ['^xpsuiPortalArticleContentEdit', '^ngModel'],
			link: function(scope, elm, attrs, ctrls) {
				log.group('portal-widget-twovideosinrow-edit Link');

				elm.empty();
				elm.addClass('x-portal-widget-edit');

				var titleBar = angular.element('<div class="xpsui-portal-widget-title-bar">{{data.meta.type}}:{{data.meta.name}}<div class="pull-right-widget"><i class="action-button icon-chevron-up" ng-click="moveUp();"></i><i class="action-button icon-chevron-down" ng-click="moveDown();"></i><i class="action-button icon-trash" ng-click="remove();"></i></div></div>');
				var content = angular.element(
					'<form class="form">'
					+'<fieldset class="form-group" style="background-color: black; padding: 10px;" ng-show="mode !== \'edit\'">'
					+'<div style="width:100%; background-color:white; display:flex">'
						+'<button ng-click="showDetails1 = !showDetails1" class="select-video-or-photo">Video1</button>'
						+'<button ng-click="showDetails2 = !showDetails2" class="select-video-or-photo">Video2</button>'
						+'<button ng-click="showDetails3 = !showDetails3" class="select-video-or-photo">Image1</button>'
						+'<button ng-click="showDetails4 = !showDetails4" class="select-video-or-photo">Image2</button>'
					+'</div>'
					+'<div style="padding-top: 10px; padding-bottom: 10px; background-color: white; float: left;width: 50%;" ng-show="showDetails1">'
					+' <div class="form-group row">'
					+'  <label class="col-sm-2 control-label">Video embed URL:</label>'
					+'  <div class="col-sm-4"><div class="input-group"><input ng-model="data.data.src" ng-change="changeUrlVideoInWidget()"/></div></div>'
					+' </div>'
					+'</div>'
					+'<div style="padding-top: 10px; padding-bottom: 10px; background-color: white;" ng-show="showDetails2">'
					+' <div class="form-group row">'
					+'  <label class="col-sm-2 control-label">Video embed URL:</label>'
					+'  <div class="col-sm-4"><div class="input-group"><input ng-model="data.data.src2" ng-change="changeUrlVideoInWidget()"/></div></div>'
					+' </div>'
					+'</div>'
					+'<div ng-show="showDetails3" class="psui-wrapper" style="text-align: center;float: left;width: 50%;">'
						+'<div xpsui-uploadable-image="" xpsui-imageresizor="" psui-width={{data.config.width}} psui-height={{data.config.height}} ng-model="data.data.img" style="width: 100% !important; height: 492px !important; background-image: url(https://localhost:3443/img/no_image.jpg); margin:0;" class="xpsui-uploadable-image xpsui-imageresizor"></div>'
						+'<button ng-click="clear1()">Clear</button>'
					+'</div>'
					+'<div ng-show="showDetails4" class="psui-wrapper" style="text-align: center;float: left;width: 50%;">'
						+'<div xpsui-uploadable-image="" xpsui-imageresizor="" psui-width={{data.config.width}} psui-height={{data.config.height}} ng-model="data.data.img2" style="width: 100% !important; height: 492px !important; background-image: url(https://localhost:3443/img/no_image.jpg); margin:0;" class="xpsui-uploadable-image xpsui-imageresizor"></div>'
						+'<button ng-click="clear2()">Clear</button>'
					+'</div>'
					+'</fieldset>'
					+'</form>'
				);

				elm.append(titleBar);
				elm.append(content);

				$compile(titleBar)(scope);
				$compile(content)(scope);

				scope.changeUrlVideoInWidget = function() {
					var fixURL = scope.data.data.src;
					scope.data.data.src = fixURL.replace("watch?v=", "embed/");
					var fixURL2 = scope.data.data.src2;
					scope.data.data.src2 = fixURL2.replace("watch?v=", "embed/");
				};

				scope.clear1 = function() {
				    scope.data.data.img = "https://localhost:3443/img/no_image.jpg";
				}
				scope.clear2 = function() {
				    scope.data.data.img2 = "https://localhost:3443/img/no_image.jpg";
				}

				scope.moveUp = function() {
					ctrls[0].moveUp(scope.index);
				};

				scope.moveDown = function() {
					ctrls[0].moveDown(scope.index);
				};

				scope.remove = function() {
					ctrls[0].remove(scope.index);
				};

				log.groupEnd();
			}
		};
	}]);

}(window.angular));


