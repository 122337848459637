(function(angular) {
	'use strict';

	angular.module('xpsui:directives')
	.directive('xpsuiTrim', ['xpsui:logging', function(log) {
		return {
			restrict: 'A',
			require: ['ngModel', '?^xpsuiFormControl'],
			link: function(scope, elm, attrs, ctrls) {

				log.group('Trim Link');

				var ngModel = ctrls[0];
				scope.ngModelController = ctrls[0];
				var formControl = ctrls[1] || {};
				var selfControl = ctrls[2] || {};

				function formatter(value) {
				    if (value) {

				    	if (elm[0].attributes['xpsui-trim']) {
							var mod = elm[0].attributes['xpsui-trim'].nodeValue;

							if (mod === 'start') {
					          value = value.replace(/^[\s\uFEFF\xA0]+/g, '');
					        } else if (mod === 'end') {
					          value = value.replace(/[\s\uFEFF\xA0]+$/g, '');
					        } else if (mod === 'both') {
					          value = value.replace(/^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g, '');  
					        }

						}

				    	return value;
				    }  
				}

				ngModel.$formatters.push(formatter);

				log.groupEnd();
			}
		};
	}]);

}(window.angular));

