(function(angular) {
  'use strict';

  angular.module('xpsui:directives')
  .directive('xpsuiPortalWidgetRefBodyEdit', ['xpsui:logging', '$compile', function(log, $compile) {
    return {
      restrict: 'A',
      scope: {
        data: '=xpsuiPortalWidgetRefBodyEdit',
        index: '='
      },
      require: ['^xpsuiPortalArticleContentEdit'],
      link: function(scope, elm, attrs, ctrls) {
        log.group('portal-widget-ref-body-edit Link');

        elm.empty();
        elm.addClass('x-portal-widget-edit');
        elm.addClass('x-portal-widget-ref-body-edit');

        var titleBar = angular.element('<div class="xpsui-portal-widget-title-bar">{{data.meta.type}}:{{data.meta.name}}<div class="pull-right-widget"><i class="action-button icon-chevron-up" ng-click="moveUp();"></i><i class="action-button icon-chevron-down" ng-click="moveDown();"></i><i class="action-button icon-trash" ng-click="remove();"></i></div></div>');

        var editor = angular.element('<div class="x-portal-widget-ref-body-edit-editor" ng-model="data.data.body" xpsui-contenteditable="true"><div></div></div>');

        var img = angular.element('<div class="psui-wrapper" style="text-align: center;"><div xpsui-uploadable-image="" xpsui-imageresizor="" psui-width={{data.config.width}} psui-height={{data.config.height}} ng-model="data.data.img" style="margin:0; float:right; background-image: url(https://localhost:3443/img/no_image.jpg); margin:0;"></psui-uploadable-image></div><div style="clear:both; height: 10px;"></div>');

        elm.append(titleBar);
        elm.append(editor);
        elm.append(img);

        $compile(titleBar)(scope);
        $compile(editor)(scope);
        $compile(img)(scope);

        scope.moveUp = function() {
          ctrls[0].moveUp(scope.index);
        };

        scope.moveDown = function() {
          ctrls[0].moveDown(scope.index);
        };

        scope.remove = function() {
          ctrls[0].remove(scope.index);
        };

        log.groupEnd();
      }
    };
  }]);

}(window.angular));
