(function(angular, moment) {
	'use strict';

	/**
	 * local constants
	 */
	var consts = {
		PROP_FUNC: 'func'
	};


	angular.module('xpsui:services')
	.factory('xpsui:calculator2', [
		'$q',
		'xpsui:logging',
		'xpsui:SchemaTools',
		'$injector',
		'$http',
		function($q, log, schemaTools, $injector, $http) {
			/**
			 * Easy object access using dot notation
			 *
			 * TODO: Move this to some utils module
			 *
			 * @param obj
			 * @param key
			 * @param def
			 * @returns {*}
			 */
			function dotAccess(obj, key, def) {
				key = Array.isArray(key) ? key : key.split('.');

				for (var i = 0, l = key.length; i < l; i++) {
					var part = key[i];
					//FIXME this construction is probably not ok as it cannot return null nor false
					if (typeof obj === 'undefined' || obj === null) {
						return def;
					}
					if (!(part in obj)) {
						return def;
					}
					obj = obj[part];
				}
				return obj;
			}

			function dotAccessSet(obj, key, value) {
				key = Array.isArray(key) ? key : key.split('.');
				for (var i = 0, l = key.length; i < l; i++) {
					var part = key[i];
					//FIXME this construction is probably not ok as it cannot return null nor false
					if (typeof obj === 'undefined' || obj === null) {
						return false;
					}
					if (!(part in obj)) {
						obj[part] = obj;
					}
					if (l === i+1) {
						obj[part] = value;
						return true;
					} else {
						obj = obj[part];
					}
				}
			}

			/**
			 * CTX:
			 * - scope: general scope, shall not be modified by function
			 * - model: shortcut to model, shall not be modified by function
			 * - local: temporary scope
			 *
			 */
			var CalcFuncs = {
				noop: function(ctx) {
					return ctx.args;
				},
				get: function(ctx) {
					if (ctx.args) {
						if (ctx.args.path) {
							if (ctx.args.path.startsWith('model.')) {
								return dotAccess(ctx.model, ctx.args.path.substr(6), ctx.args.default);
							} else if (ctx.args.path.startsWith('scope.')) {
								return dotAccess(ctx.scope, ctx.args.path.substr(6), ctx.args.default);
							} else if (ctx.args.path.startsWith('local.')) {
								return dotAccess(ctx.local, ctx.args.path.substr(6), ctx.args.default);
							} else if (ctx.args.path.startsWith('tmp.')) {
								return dotAccess(ctx.tmp, ctx.args.path.substr(4), ctx.args.default);
							} else if (ctx.args.path.startsWith('user.')) {
								return dotAccess($injector.get('$rootScope'),
									'security.currentUser.'.concat(ctx.args.path.substr(5)),
									ctx.args.default);
							}
						} else if (ctx.args.scopePath) {
							return dotAccess(ctx.scope, ctx.args.scopePath, ctx.args.default);
						} else if (ctx.args.modelPath) {
							return dotAccess(ctx.model, ctx.args.modelPath, ctx.args.default);
						} else if (ctx.args.localPath) {
							return dotAccess(ctx.local, ctx.args.localPath, ctx.args.default);
						} else if (ctx.args.tmpPath) {
							return dotAccess(tmp.local, ctx.args.tmpPath, ctx.args.default);
						} else if (ctx.args.userPath) {
							return dotAccess($injector.get('$rootScope'),
								'security.currentUser.'.concat(ctx.args.userPath),
								ctx.args.default);
						} else {
							// FIXME wrap to psui error
							throw 'No path specified';
						}
					} else {
						// FIXME wrap to psui error
						throw 'No args provided';
					}
				},

				set: function(ctx) {
					if (!('value' in ctx.args)) {
						ctx.args.value = ctx.local;
					}

					if (ctx.args) {
						if (ctx.args.path) {
							return dotAccessSet(ctx, ctx.args.path, ctx.args.value);
						} else {
							// FIXME wrap to psui error
							throw 'No path specified';
						}
					} else {
						// FIXME wrap to psui error
						throw 'No args provided';
					}
				},

				if: function(ctx) {
					var args = ctx.args;

					if (args.test) {
						return args.then;
					} else {
						return args.else;
					}
				},

				equal: function(ctx) {
					if (ctx.args.test1 === ctx.args.test2) {
						return true;
					} else {
						return false;
					}
				},

				greater: function(ctx) {
					if (ctx.args.what > ctx.args.then) {
						return true;
					} else {
						return false;
					}
				},

				negation: function(ctx) {
					if (ctx.args.value) {
						return false;
					} else {
						return true;
					}
				},

				mathModulo: function(ctx) {
					var dividend = ctx.args.dividend;
					var divisor = ctx.args.divisor;

					return dividend % divisor;
				},

				stringToNumber: function(ctx) {
					var string = ctx.args.string;

					return parseInt(string);
				},

				regExpressionExec: function(ctx) {
					var pattern = ctx.args.pattern;
					var flag = ctx.args.flag;
					var text = ctx.args.text;
					var match = null;
					var matchs = [];

					var regExp = new RegExp(pattern, flag);

					if (flag.lastIndexOf('g') > -1) {
						while ((match = regExp.exec(text)) != null) {
							matchs.push(match);
						}
						return matchs;
					} else {
						return regExp.exec(text);
					}

					return new RegExp(pattern, flag).exec(text)
				},

				merge: function(ctx) {
					var args = ctx.args;

					var v;
					var res = {};

					for (v in args) {
						_.merge(res, args[v]);
					}

					return res;
				},

				criterion: function(ctx) {
					var r = {
						f: ctx.args.f,
						op: ctx.args.op,
						v: ctx.args.v
					};

					if (ctx.args.nullIfEmpty && ctx.args.v === null) {
						return null;
					}

					return r;
				},

				criteriaList: function(ctx) {
					var r = [];

					for (var i in ctx.args) {
						if (ctx.args[i] !== null) {
							r.push(ctx.args[i]);
						}
					}
					
					return r;
				},

				/**
				 * Get value from object
				 *
				 * @param {object} args Args must be an object with "path" property and
				 * 			obj property
				 * @param {object} scope
				 * @param {*} def
				 * @returns {*}
				 */
				getFrom: function(ctx) {
					var path = ctx.args.path;
					var obj = ctx.args.obj;
					var def = ctx.args.default;

					return dotAccess(obj, path, def);
				},

				/**
				 * gets data from server as objectlink
				 *
				 * args.objectId - id of object to get
				 * args.schemaUri - to use
				 * args.fields - see objectlink
				 * @param {object} args
				 */
				getAsObjectLink: function(ctx) {
					log.trace('getAsObjectLink func');
					var id = ctx.args.objectId,
						schema = ctx.args.schemaUri,
						fields = ctx.args.fields;

					if (!schema) {
						log.error('Schema is not defined');
					}

					if (!id) {
						log.trace('No id provided');
						return null;
					}

					var p = $q.defer();

					log.trace('getAsObjectLink func', schema, id);
					schemaTools.getBySchema(schema, id, fields).then(
						function(data) {
							log.trace(data);
							p.resolve(data);
						},
						function(err) {
							log.trace(err);
							p.reject(err);
						}
					);

					return p.promise;
				},

				/**
				 * Updating of input object with object of argument. (Opposite of mergeObjectSetDefault())
				 *
				 * args.obj - input object
				 * args.update - properties for update
				 * @param {object} args
				 */
				mergeObjectUpdate: function(ctx) {
					var obj = ctx.args.obj || ctx.local;

					if (!Array.isArray(obj)) {
						obj = [obj];
					}

					if ('update' in ctx.args) {
						for (var i in obj) {
							obj[i] = _.defaultsDeep(_.cloneDeep(ctx.args.update), obj[i]);
						}
					}

					return $q.when(obj);
				},

				/**
				 * Set default properties of object of argument for input object. (Opposite of mergeObjectUpdate())
				 *
				 * args.obj - input object
				 * args.default - default properties
				 * @param {object} args
				 */
				mergeObjectSetDefault: function(ctx) {
					var obj = ctx.args.obj || ctx.local;

					if (!Array.isArray(obj)) {
						obj = [obj];
					}

					if ('default' in ctx.args) {
						for (var i in obj) {
							obj[i] = _.defaultsDeep(obj[i], ctx.args.default);
						}
					}

					return $q.when(obj);
				},

				nowAsDateTimeString: function(ctx) {
					var r = moment().format('DD.MM.YYYY HH:mm:ss.SSS');
					return $q.when(r);
				},

				nowAsDateDbString: function(ctx) {
					var r = moment().format('YYYYMMDD');
					return $q.when(r);
				},

				/*
				 * Shift in input time.
				 * args = {years, months, days, hours, minutes, seconds}
				 */
				dateShift: function(ctx) {
					var timestamp;

					if (ctx.args.dateTime.length === 8) {
						timestamp = moment(ctx.args.dateTime, 'YYYYMMDD').format('x');
					} else {
						timestamp = ctx.args.dateTime;
					}

					var dateTime = new Date(parseInt(timestamp));

					dateTime.setFullYear((ctx.args.year || ctx.args.years || 0) + dateTime.getFullYear());
					dateTime.setMonth((ctx.args.month || ctx.args.months || 0) + dateTime.getMonth());
					dateTime.setDate((ctx.args.date || ctx.args.day || ctx.args.days || 0) + dateTime.getDate());
					dateTime.setHours((ctx.args.hour || ctx.args.hours || 0) + dateTime.getHours());
					dateTime.setMinutes((ctx.args.minute || ctx.args.minutes || ctx.args.min || 0) + dateTime.getMinutes());
					dateTime.setSeconds((ctx.args.second || ctx.args.seconds || ctx.args.sec || 0) + dateTime.getSeconds());

					return moment(dateTime.getTime()).format(ctx.args.format);
				},

				concat: function(ctx) {
					var keys = Object.keys(ctx.args).sort(),
						result = '';
					for (var i = 0; i < keys.length; i++) {
						result = result.concat(ctx.args[keys[i]]);
					}
					return result;
				},

				concatArray: function(ctx) {
					var keys = Object.keys(ctx.args).sort(),
						result = [];
					for (var i = 0; i < keys.length; i++) {
						result = result.concat(ctx.args[keys[i]]);
					}
					return result;
				},

				simpleArray: function(ctx) {
					var a = ctx.args.array || [];
					var f = ctx.args.field;

					return a.map(function(v) {
						return _.get(v,f);
					});
				},

				arrayIsNotEmpty: function(ctx) {
					var a = ctx.args.array || [];
					return a.length > 0;
				},
				
				valueInArray: function(ctx) {
					var array = ctx.args.array || [];
					var path = ctx.args.path || '';
					var value = ctx.args.value;
					
					return array.includes(value)
				},

				/**
				 * Expression returns an array of values whose path or value are present in the input array.
				 */
				existInArray: function(ctx) {
					var array = ctx.args.array || [];
					var path = ctx.args.path || [];
					var value = ctx.args.value;
					var results = [];

					if (typeof path === 'string') {
						path = path.split('.');
					}

					for (var a in array) {
						var result = array[a];

						for (var p in path) {
							if (path[p] in result) {
								result = result[path[p]];
							} else {
								result = false;
								return;
							}
						}
						
						if (result && value !== undefined && result != value) {
							result = false;
						}

						if (result) {
							results.push(array[a]);
						}
					}

					return results;
				},

				rejectIf: function(ctx) {
					if (ctx.args.test) {
						return $q.reject({userMassageNotification: ctx.args.massage});
					} else {
						return true;
					}
				},

				mapToObject: function(ctx) {
					// FIXME use lodash for this, dont forget it does not set nulls
					function set(obj, path, value) {
						if (!value) {
							return;
						}

						var p = (path || '').split('.');

						var objFragment, pathFragment;

						objFragment = obj;

						while (p.length > 0) {
							pathFragment = p.shift();

							if (!angular.isObject(objFragment[pathFragment])) {
								objFragment[pathFragment] = {};
							}

							if (p.length === 0) {
								objFragment[pathFragment] = value;
								return;
							} else {
								objFragment = objFragment[pathFragment];
							}
						}
					}

					log.trace('mapToObject');

					var map = ctx.args.mapping || {};
					var dest = ctx.args.destination || {};

					var val, key, lCtx;

					for (var k in map) {
						if (k.startsWith('!!')) {
							k = k.substr(2);

							if (map['!!' + k] === null) {
								_.unset(dest, k);
							}
						}
						if (map.hasOwnProperty(k)) {
							set(dest, k, map[k]);
						}
					}

					return $q.when(dest);
				}
			};

			var Ctx = function(scope, model, local, tmp) {
				this.scope = scope;
				this.model = model;
				this.local = local || {};
				this.args = {};
				this.parentCtx = null;
				this.tmp = tmp;
			};

			/**
			 * creates new context as copy of current ctx and binds
			 * 'this' ctx as it's nested context;
			 */
			Ctx.prototype.withArgs = function(args) {
				var r = new Ctx(this.scope, this.model, this.local, this.tmp);
				r.args = args;
				r.parentCtx = this;

				return r;
			};

			var Calculator = function(def) {
				this.def = def;
			};

			Calculator.prototype.execute = function(ctx, def) {
				var lDef;
				if (typeof def !== 'undefined') {
					lDef = def;
				} else {
					lDef = this.def;
				}
				var cFunc = CalcFuncs.noop;
				var lArgs = {};



				if (lDef && Array.isArray(lDef)) {
					lArgs = [];

					for (var i in lDef) {
						lArgs[i] = this.execute(ctx.withArgs({}), lDef[i]);
					}
				} else if (lDef && typeof lDef === 'object' && lDef.hasOwnProperty(consts.PROP_FUNC)) {
					// definition is calculation

					cFunc = CalcFuncs[lDef[consts.PROP_FUNC]];

					if (typeof cFunc !== "function") {
						console.error("Expression '" + lDef[consts.PROP_FUNC] + "' does not exist!");
					}

					if (lDef.args && angular.isArray(lDef.args)) {
						lArgs = [];
						for (var e in lDef.args) {
							//FIXME this is dirty hack to resolve get shortcuts, better design would be welcome
							if (angular.isString(lDef.args[e]) && ''.startsWith && lDef.args[e].startsWith('@@')) {
								// we can replace it as it is pure string
								lDef.args[e] = {
									func: 'get',
									args: {
										path: lDef.args[e].substr(2)
									}
								};
							}
							lArgs.push(this.execute(ctx.withArgs([]), lDef.args[e]));
						}
					} else {
						for (var a in lDef.args || {}) {
							//FIXME this is dirty hack to resolve get shortcuts, better design would be welcome
							if (angular.isString(lDef.args[a]) && ''.startsWith && lDef.args[a].startsWith('@@')) {
								// we can replace it as it is pure string
								lDef.args[a] = {
									func: 'get',
									args: {
										path: lDef.args[a].substr(2)
									}
								};
							}
							if (lDef.args.hasOwnProperty(a)) {
								lArgs[a] = this.execute(ctx.withArgs({}), lDef.args[a]);
							}
						}
					}
				} else {
					// definition is not calculation
					return $q.when(lDef);
				}

				return $q.all(lArgs).then(function(pArgs) {
					return cFunc(ctx.withArgs(pArgs));
				});
			};

			return {
				createCalculator: function(def) {
					return new Calculator(def);
				},
				createCtx: function(scope, model, local, tmp) {
					return new Ctx(scope, model, local, tmp);
				}
			};
		}]);
}(window.angular, window.moment));
