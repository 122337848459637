(function(angular) {
	'use strict';

	angular.module('xpsui:directives')
	.directive('xpsuiPortalWidgetShopItemView', ['xpsui:logging', '$compile', function(log, $compile) {
		return {
			restrict: 'A',
			scope: {
				data: '=xpsuiPortalWidgetShopItemView',
				index: '='
			},
			link: function(scope, elm, attrs, ctrls) {
				log.group('portal-widget-shop-item-view Link');

				elm.empty();
				elm.addClass('x-portal-widget-view');
				elm.addClass('x-portal-widget-shop-item-view');

				var content = angular.element('<div class="x-portal-widget-shop-item-view-view-content">' +
					'<article class="item">' +
							'<div class="time" ng-bind="data.data.date"></div>' +
							'<div class="preview" ng-src="{{data.data.img}}" style="width: 167px !important; height: 242px !important; background-image: url({{data.data.img}});"></div>'+
							'<div class="texts">' +
								'<h2><a ng-bind="data.data.title"></a></h2>' +
								'<div class="flexed">' +
									'<dl>' +
										'<dt ng-bind="data.data.basic1"></dt>' +
										'<dd ng-bind="data.data.result1"></dd>' +
									'</dl>' +
									'<dl>' +
										'<dt>Cena:</dt>' +
										'<dd ng-bind="data.data.price"></dd>' +
									'</dl>' +
									'<dl>' +
										'<dt>Lokalita:</dt>' +
										'<dd ng-bind="data.data.location"></dd>' +
									'</dl>' +
								'</div>' +
								'<p ng-bind="data.data.about"><a>čítať viac <i class="fa fa-chevron-right"></i></a></p>' +
								'<div class="flexed">' +
									'<p ng-bind="data.data.info1"><i class="fa fa-user"></i></p>' +
									'<p ng-bind="data.data.info2"><i class="fa fa-phone"></i></p>' +
									'<p ng-bind="data.data.info3"><i class="fa fa-envelope"></i></p>' +
								'</div>' +
							'</div>' +
						'</article>' +
				'</div>');
				elm.append(content);

				$compile(content)(scope);

				log.groupEnd();
			}
		};
	}]);

}(window.angular));


