(function(angular) {
	'use strict';

	angular.module('xpsui:controllers')
	.controller('xpsui:PersonalPageCtrl', [
		"$scope",
		"$location",
		function($scope, $location) {
			console.log('/2/registry/view/uri~3A~2F~2Fregistries~2Fpeople~23views~2FpersonalProfile~2Fview/'+$scope.security.currentUser.id);
			$location.path('/2/registry/view/uri~3A~2F~2Fregistries~2Fpeople~23views~2FpersonalProfile~2Fview/'+$scope.security.currentUser.id);
		}
	]);
}(window.angular));
