(function(angular) {
	'use strict';

	angular.module('xpsui:directives')
	.directive('xpsuiDateEdit', ['xpsui:logging', function(log) {
		return {
			restrict: 'A',
			require: ['ngModel', '?^xpsuiFormControl', 'xpsuiDateEdit'],
			controller: function($scope, $element, $attrs) {
				this.setup = function(){
					this.$input = angular.element('<input></input>');
				}
				
				this.getInput = function(){
					return this.$input;
				}

				this.$input = null;
				this.setup();
				
			},
			link: function(scope, elm, attrs, ctrls) {

				log.group('String edit Link');

				var ngModel = ctrls[0];
				var formControl = ctrls[1] || {};
				var selfControl = ctrls[2];

				var input = selfControl.getInput();

				elm.addClass('x-control');
				elm.addClass('x-date-edit');

				ngModel.$render = function() {
					input.val(ngModel.$viewValue || '');
					formControl.oldValue = ngModel.$modelValue;
				};
				//ngModel.$setValidity('valid', false);
				elm.append(input);

				input.on('change', function(evt) {
					if (input.val()) {
						if (!isDate(input.val(), '.') && input.val()) {
							ngModel.$setValidity('valid', false);
						} else {
							ngModel.$setValidity('valid', true);
						}
					} else {
						ngModel.$setValidity('valid', true);
						var resultDate = regEx(input.val());
						input[0].value = resultDate;
						ngModel.$setViewValue(resultDate);
					}
					scope.$apply(function() {
						var resultDate = regEx(input.val());
						input[0].value = resultDate;
						ngModel.$setViewValue(resultDate);
					});					
				});

				input.on('keydown', function(evt) {
					if (input.val()) {
						if (!isDate(input.val(), '.') && input.val()) {
							ngModel.$setValidity('valid', false);
						} else {
							ngModel.$setValidity('valid', true);
						}
					} else {
						ngModel.$setValidity('valid', true);
					}
					scope.$apply(function() {
						var resultDate = regEx(input.val());
						input[0].value = resultDate;
						ngModel.$setViewValue(resultDate);
					});
				});

				input.on('blur', function(evt) {
					if (input.val()) {
						if (!isDate(input.val(), '.') && input.val()) {
							ngModel.$setValidity('valid', false);
						} else {
							ngModel.$setValidity('valid', true);
						}
					} else {
						ngModel.$setValidity('valid', true);
					}
					scope.$apply(function() {
						var resultDate = regEx(input.val());
						input[0].value = resultDate;
						ngModel.$setViewValue(resultDate);
					});
				});

				elm.bind('focus', function(evt) {
					input[0].focus();
				});

				log.groupEnd();

				function regEx(date) {
					return date.replace(/\s/g, '')
				}

				function isDate(txtDate, separator) {
					var aoDate,           // needed for creating array and object
					ms,               // date in milliseconds
					month, day, year; // (integer) month, day and year
					// if separator is not defined then set '/'
					if (separator === undefined) {
						separator = '.';
					}
					// split input date to month, day and year
					aoDate = txtDate.split(separator);
					// array length should be exactly 3 (no more no less)
					if (aoDate.length !== 3) {
						return false;
					}
					// define month, day and year from array (expected format is m/d/yyyy)
					// subtraction will cast variables to integer implicitly
					day = aoDate[0] - 0; // because months in JS start from 0
					month = aoDate[1] - 1;
					year = aoDate[2] - 0;
					// test year range
					if (year < 1000 || year > 3000) {
						return false;
					}
					// convert input date to milliseconds
					ms = (new Date(year, month, day)).getTime();
					// initialize Date() object from milliseconds (reuse aoDate variable)
					aoDate = new Date();
					aoDate.setTime(ms);
					// compare input date and parts from Date() object
					// if difference exists then input date is not valid
					if (aoDate.getFullYear() !== year ||
						aoDate.getMonth() !== month ||
						aoDate.getDate() !== day) {
						return false;
					}
					// date is OK, return true
					return true;
				}
			}

		};
	}]);

}(window.angular));

