(function(angular) {
	'use strict';
	
	angular.module('xpsui:directives')
	.directive('xpsuiPortalMenuThirdLvlRender', ['$http', '$route', '$compile', '$location', 
	function($http, $route, $compile, $location) {
		return {
			restrict: 'A',
			link: function(scope, elm, attrs, ctrls) {
				var menuHash = [];
				var counter = 0;
				var parentMenuIdx = attrs.parentIdx
				var parentMenuSubIdx = attrs.parentSubIdx

				function parse(string, path) {
					var result;
					try {
						result = _.get(JSON.parse(string), path);
					} catch(err) {
						result = string;
					}
					return result;
				}
				function renderMenuEntry(data, element) {
					var menuEntry = angular.element('<div class="portal-local-menu"></div>');

					for (var i = 0; i < data.subElements.length; ++i) {
					var subMenu = angular.element('<div class="portal-local-menu-item" ng-click=navigate3rdLvl('+ (counter++) +')>'+ parse(data.subElements[i].name, 'SK')+'</div>');
						menuHash.push(data.subElements[i].tags);
					menuEntry.append(subMenu);
					}
					return(menuEntry);

				}

				$http({
					method : 'GET',
					url: '/udao/list/portalMenu',
					data: {
					}
				})
				.success(function(data, status, headers, config){
					elm.empty();
					if (data && data.length > 0 && data[0].index) {
							var menuEntry = renderMenuEntry(data[0].index.subElements[parentMenuIdx].subElements[parentMenuSubIdx]);
							elm.append(menuEntry);
							$compile(menuEntry)(scope);
					}
				}).error(function(err) {
					notificationFactory.error(err);
				});

				scope.navigate3rdLvl = function(i) {
					$http({
						method : 'POST',
						url: '/portalapi/getByTags',
						data: {
							tags: menuHash[i]
						}
					})
					.success(function(data, status, headers, config){
						if (data && data.length > 0 && data[0].id) {
							$location.path('/portal/edit/'+ data[0].id);
							//$route.updateParams({id: data[0].id});
						}
					}).error(function(err) {
						notificationFactory.error(err);
					});
				};
			}
		};
	}]);
}(window.angular));
