(function(angular) {
	'use strict';

	angular.module('xpsui:directives')
	.directive('xpsuiPortalWidgetPollView', ['xpsui:logging', '$compile', function(log, $compile) {
		return {
			restrict: 'A',
			scope: {
				data: '=xpsuiPortalWidgetPollView',
				index: '='
			},
			link: function(scope, elm, attrs, ctrls) {
				log.group('portal-widget-poll-view Link');

				elm.empty();
				elm.addClass('x-portal-widget-view');
				elm.addClass('x-portal-widget-poll-view');


				var content = angular.element(
					'<div ng-repeat="block in data.data.blocks" class="x-portal-widget-poll-view-block-wrapper">' +
						'<div class="x-portal-widget-poll-view-block">' +
							'<div ng-switch on="block.type">' +
								'<div ng-switch-when="heading-1" class="x-portal-widget-poll-view-block-heading-1">' +
									'{{block.text}}' +
								'</div>' +
								'<div ng-switch-when="heading-2" class="x-portal-widget-poll-view-block-heading-2">' +
									'{{block.text}}' +
								'</div>' +
								'<div ng-switch-when="text" class="x-portal-widget-poll-view-block-text">' +
									'{{block.text}}' +
								'</div>' +
								'<div ng-switch-when="answers" class="x-portal-widget-poll-view-block-answers">' +
									'<div ng-repeat="answer in block.answers" class="x-portal-widget-poll-view-block-answers-answer">' +
										'<div class="answer-photo" style="background-image: url(\'{{answer.image}}\'); width: {{$parent.data.config.profilePhoto.width}}px;  height: {{$parent.data.config.profilePhoto.height}}px;">' +
										'</div>' +
										'<div class="answer-desc">' +
											'<div class="name">{{answer.name}}</div>' +
											'<div class="surname">{{answer.surname}}</div>' +
											'<div class="club">{{answer.club}}</div>' +
										'</div>' +
									'</div>' +
								'</div>' +
							'</div' +
						'</div>' +
					'</div>'
				);

				elm.append(content);

				$compile(content)(scope);

				log.groupEnd();
			}
		};
	}]);

}(window.angular));


