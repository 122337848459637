(function(angular) {
	'use strict';

	angular.module('xpsui:directives')
	.directive('xpsuiFormatPattern', ['$http', 'xpsui:SchemaUtil', 'xpsui:logging', 'xpsui:FormGenerator', '$compile', 'xpsui:QueryFilter', function($http, schemaUtilFactory, log, formGenerator, $compile, QueryFilter) {

		return { 
			restrict: 'A',
			require: ['?ngModel'],

			link: function(scope, element, attrs, controllers, elm) {

				var	schemaOpt = scope.$eval(attrs.xpsuiSchema); 
				var ngModelCtrl = controllers[0];

				scope.$watchCollection(function(scope) {
		            return scope.$eval(attrs.ngModel)
		        }, function(newVal, oldVal){

		            validation();

		        }, true);
				
				scope.$watch('ngModelController.$modelValue', function(newVal, oldVal){

		            if (newVal) {

		              validation();

		            }

		        }, true);


		        function validation() {
		        	var value = _.cloneDeep(ngModelCtrl.$modelValue || ngModelCtrl.$viewValue);

					if (value != '') {
						if (value && typeof value === 'string') {
							var result = value.match(new RegExp(schemaOpt.formatPattern.regexp || '.*'), schemaOpt.formatPattern.modifier || 'i');

							if (result === null) {
								console.log('Value is not in required format.');
								return ngModelCtrl.$setValidity('xpsuiFormatPattern', false);
							} else {
								return ngModelCtrl.$setValidity('xpsuiFormatPattern', true);
							}

						}
					} else {
						return ngModelCtrl.$setValidity('xpsuiFormatPattern', true);
					}

					
		        }

			}
		};

	}]);
}(window.angular));