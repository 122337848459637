(function(angular) {
	'use strict';

	angular.module('xpsui:directives')
	.directive('xpsuiPortalWidgetJobsMainView', ['xpsui:logging', '$compile', function(log, $compile) {
		return {
			restrict: 'A',
			scope: {
				data: '=xpsuiPortalWidgetJobsMainView',
				index: '='
			},
			link: function(scope, elm, attrs, ctrls) {
				log.group('portal-widget-jobs-main-view Link');

				elm.empty();
				elm.addClass('x-portal-widget-view');
				elm.addClass('x-portal-widget-jobs-main-view');

				var content = angular.element('<div class="x-portal-widget-jobs-main-view-view-content">' +
					'<div class="jobsBlock">' +
						'<h1 class="jobHeadline" ng-bind="data.data.title"></h1>' +
						'<div class="jobSubtext" ng-bind="data.data.caption"></div>' +
						'<div ng-repeat="photo in data.data.position" class="psui-wrapper x-portal-widget-jobs-main-view-block">' +
							'<div class="job">' +
								'<div class="x-portal-widget-jobs-main-view-text jobTitle" ng-bind="photo.position"></div>' +
								'<div class="x-portal-widget-jobs-main-view-text jobButton">More Details</div>' +
							'</div>' +
							'<hr class="divider" />' +
						'</div>' +
						'<div class="button">' +
							'<a href="/article/579612cb25425a8636022ef2" ng-bind="data.data.remaining"></a>' +
						'</div>' +
					'</div>' +
				'</div>');
				elm.append(content);

				$compile(content)(scope);

				log.groupEnd();
			}
		};
	}]);

}(window.angular));


