(function(angular) {
	'use strict';

	angular.module('xpsui:controllers')
	.controller('xpsui:OnlinePayment', [
		'$scope',
		'$routeParams',
		'$http',
		'$location',
		'$parse',
		'xpsui:SchemaUtil',
		'xpsui:NotificationFactory',
		'xpsui:ObjectTools',
		function($scope, $routeParams, $http, $location, $parse, schemaUtils, notificationFactory, objectTools) {
			$scope.currentSchema = $routeParams.schema;
			$scope.currentId = $routeParams.id;
			$scope.currentSchemaUri = schemaUtils.decodeUri($routeParams.schema);

			$scope.model = {
				obj: {},
				actions: []
			};

			$scope.schemaFormOptions = {
				modelPath: 'model.obj',
				schema: {}
			};

			$scope.fragmentedUpdateAllowed = false;

			var schemaUri = schemaUtils.decodeUri($scope.currentSchema);

			schemaUtils.getCompiledSchema(schemaUri)
			.then(function(resp) {
				// schema load success
				$scope.schemaFormOptions.schema = resp.data;
				$scope.fragmentedUpdateAllowed = resp.data.fragmentedUpdateAllowed;

				if ($scope.schemaFormOptions.schema.actions
					&& angular.isArray($scope.schemaFormOptions.schema.actions)) {
					$scope.schemaFormOptions.schema.actions.map(function(i) {
						$scope.model.actions.push(i);
					});
				}

				$http({method: 'GET', url: '/udao/getBySchema/' + schemaUtils.encodeUri(schemaUri) + '/' + $scope.currentId})
				.then(function(resp2) {
					schemaUtils.generateObjectFromSchema($scope.schemaFormOptions.schema, $scope.model.obj);
					$scope.model.obj = resp2.data;
				}, function(resp2) {
					notificationFactory.error(resp2.data);
				});
			}, function(err) {
				// schema load failure
				// TODO do proper error handling, more user friendly
				notificationFactory.error(err);
			});
		}
	]);
}(window.angular));

