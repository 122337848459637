(function(angular) {
	'use strict';

	angular.module('xpsui:directives')
	.directive('xpsuiPortalWidgetShopItemEdit', ['xpsui:logging', '$compile', function(log, $compile) {
		return {
			restrict: 'A',
			scope: {
				data: '=xpsuiPortalWidgetShopItemEdit',
				index: '='
			},
			require: ['^xpsuiPortalArticleContentEdit'],
			link: function(scope, elm, attrs, ctrls) {
				log.group('portal-widget-shop-item-edit Link');

				elm.empty();
				elm.addClass('x-portal-widget-edit');
				elm.addClass('x-portal-widget-shop-item-edit');

				var titleBar = angular.element('<div class="xpsui-portal-widget-title-bar">{{data.meta.type}}:{{data.meta.name}}<div class="pull-right-widget"><i class="action-button icon-chevron-up" ng-click="moveUp();"></i><i class="action-button icon-chevron-down" ng-click="moveDown();"></i><i class="action-button icon-trash" ng-click="remove();"></i></div></div>');
				var content = angular.element(
					'<div class="x-portal-widget-shop-item-edit-content list">' +
						'<article class="item">' +
							'<div class="time"><textarea class="" ng-model="data.data.date" placeholder="Enter item\'s date.."></textarea></div>' +
							'<div class="preview">' +
								'<div xpsui-uploadable-image xpsui-imageresizor psui-width="167" psui-height="242" ng-model="data.data.img" style="height: 100% !important; background-image: url(/img/no_image.jpg);">' +
								'</xpsui-uploadable-image></div>' +
						 	'</div>'+
							'<div class="texts">' +
								'<textarea class="" ng-model="data.data.title" placeholder="Enter item\'s title.."></textarea>' +
								'<div class="flexed">' +
									'<dl>' +
										'<dt><textarea class="" ng-model="data.data.basic1" placeholder="Enter item\'s basic recognition.."></textarea></dt>' +
										'<dd><textarea class="" ng-model="data.data.result1" placeholder="Enter basic info\'s result.."></textarea></dd>' +
									'</dl>' +
									'<dl>' +
										'<dt>Cena:</dt>' +
										'<dd><textarea class="" ng-model="data.data.price" placeholder="Enter item\'s price.."></textarea></dd>' +
									'</dl>' +
									'<dl>' +
										'<dt>Lokalita:</dt>' +
										'<dd><textarea class="" ng-model="data.data.location" placeholder="Enter location.."></textarea></dd>' +
									'</dl>' +
								'</div>' +
								'<p><textarea class="" ng-model="data.data.about" placeholder="Enter item\'s description.." style="width: 100%;"></textarea><a>čítať viac <i class="fa fa-chevron-right"></i></a></p>' +
								'<div class="flexed">' +
									'<p><i class="fa fa-user"></i><textarea class="" ng-model="data.data.info1" placeholder="Enter seller\'s name.."></textarea></p>' +
									'<p><i class="fa fa-phone"></i><textarea class="" ng-model="data.data.info2" placeholder="Enter seller\'s telephone"></textarea></p>' +
									'<p><i class="fa fa-envelope"></i><textarea class="" ng-model="data.data.info3" placeholder="Enter seller\'s e-mail.."></textarea></p>' +
								'</div>' +
							'</div>' +
						'</article>' +
					'</div>');

				elm.append(titleBar);
				elm.append(content);

				$compile(titleBar)(scope);
				$compile(content)(scope);

				scope.moveUp = function() {
					ctrls[0].moveUp(scope.index);
				};

				scope.moveDown = function() {
					ctrls[0].moveDown(scope.index);
				};

				scope.remove = function() {
					ctrls[0].remove(scope.index);
				};

				log.groupEnd();
			}
		};
	}]);

}(window.angular));


