(function(angular) {
	'use strict';

	angular.module('xpsui:directives')
	.directive('xpsuiDashboardWidgetRequests', [
		'$q', 'xpsui:logging', '$compile', '$translate', '$http', '$location', 'xpsui:SchemaUtil', 'xpsui:NotificationFactory',
		'xpsui:NavigationService', 'xpsui:config', 'xpsui:StandardActions',
		function(q, log, $compile, $translate, $http, $location, schemaUtilFactory, notificationFactory,
				navigationService, config, standardActions) {
			return {
				template:
					'<div class="dashboard-widget-title">{{title}}</div>'
					+'<div class="dashboard-widget-content">'
					+'	<div class="dashboard-widget-blocks">'
					+'		<div ng-repeat="(i, widget) in widgets" class="dashboard-widget-block">'
					+'			<div ng-switch="widget.widget.widget">'
					+'				<div ng-switch-when="counter">'
					+'					<div xpsui-dashboard-widget-counter="{{i}}" ng-model="widget"></div>'
					+'				</div>'
					+'			</div>'
					+'		</div>'
					+'	</div>'
					+'</div>',
				controller: function($scope, $element, $attrs) {
					this.scope = $scope;
					this.attrs = $attrs;
					this.element = $element;
				},
				link: function(scope, elm, attrs) {

					var startExecution = function(options) {
						if (options && options.func) {
							var ctx = {
								scope: scope,
								model: scope.$parent.security.currentUser,
								args: options.args,
								local: {}
							};

							return standardActions.invoke(options.func, ctx);
						} else {
							return Promise.resolve(options);
						}
					};

					scope.widgets = [];
					scope.title = $translate.instant('dashboard.widget.notifications.title');
					scope.label_loading = $translate.instant('generic.loading');

					var widgets = config.get('dashboard') || [];
					var promisses = [];

					for (var i in widgets) {
						promisses.push(startExecution(widgets[i].accessExpr));
					}

					Promise.all(promisses)
					.then(function(data) {
						for (var i in data) {
							if (data[i]) {
								log.group('dashboard widget ' + widgets[i].widget + ' - rendering');

								scope.widgets.push({
									widget: widgets[i],
									widgetOrder: i
								});

							} else if (!data[i]) {
								log.group('dashboard widget ' + widgets[i].widget + ' - sccess denied');
							}
						}

						scope.$apply();
					});

				}
			};
		}
	]);

}(window.angular));
