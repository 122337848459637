(function(angular) {
	'use strict';

	angular.module('xpsui:directives')
	.directive('xpsuiPortalArticleContentEdit', ['xpsui:logging', '$compile', function(log, $compile) {
		return {
			restrict: 'A',
			require: ['ngModel'],
			controller: ['$scope', '$element', '$attrs', '$parse', '$timeout', function($scope, $element, $attrs, $parse, $timeout) {
				this.blockMove = function(array, a, b) {
					var tmp = array[a];
					
					array.splice(a, 1);
					array.splice(b, 0, tmp);

					var parsedModel = $parse($attrs.ngModel);
					parsedModel.assign($scope, array);
					$scope.$broadcast('modechange');
				};

				this.moveUp = function(idx) {
					this.move(idx);
				};

				this.moveDown = function(idx) {
					this.move(idx, true);
				};

				this.move = function(idx, reverse) {
					var parsedModel = $parse($attrs.ngModel);
					var sections = angular.element(document.querySelectorAll('article[ng-model="model.data"]'));
					var array = parsedModel($scope);
					var start = false;

					for (var e = (!reverse)?array.length-1:0; (!reverse && e >= 0) || (reverse && e < array.length); ) {
						if (start && !('meta' in array[e])) {
							array[e].meta = {};
						}

						if (start && (array[e].meta.sectionName || 'null') == (array[idx].meta.sectionName || 'null')) {
							return this.blockMove(array, idx, (!reverse)?e:e);
						}

						if (e === idx) {
							start = true;
						}

						if (!reverse && e >= 0) {
							e--;
						}
						if (reverse && e < array.length) {
							e++;
						}
					}

					start = false;

					for (var i = (!reverse)?sections.length-1:0; (!reverse && i >= 0) || (reverse && i < array.length); ) {
						var sectionName = 'null';

						if (sections[i] === undefined) {
							break;
						}

						if ('sectionName' in sections[i].attributes) {
							sectionName = sections[i].attributes.sectionName.nodeValue;
						}

						if (start) {
							array[idx].meta.sectionName = sectionName;
							
							if (sectionName === 'null') {
								delete array[idx].meta.sectionName;
							}

							return this.blockMove(array, idx, (!reverse)?array.length:0);
						}

						if (sectionName == (array[idx].meta.sectionName || 'null')) {
							start = true;
						}

						if (!reverse && i >= 0) {
							i--;
						}
						if (reverse && i < array.length) {
							i++;
						}
					}
				};

				this.remove = function(idx) {
					var parsedModel = $parse($attrs.ngModel);

					var array = parsedModel($scope);

					array.splice(idx, 1);

					parsedModel.assign($scope, array);

					$scope.$broadcast('modechange');
				};
			}],
			link: function(scope, elm, attrs, ctrls) {
				log.group('portal-article-content-edit Link');

				var ngModel = ctrls[0];

				var content = angular.element('<div></div>');

				ngModel.$render = function() {
					var i, block, showBlockSelector;
					elm.empty();

					var sectionName = null;
					if ('sectionName' in elm[0].attributes) {
						sectionName = elm[0].attributes.sectionName.nodeValue;
					} else {
						for (var i in elm[0].attributes) {
							if (elm[0].attributes[i].nodeName && elm[0].attributes[i].nodeName.toLowerCase() === 'sectionname') {
								sectionName = elm[0].attributes[i].nodeValue;
							}
						}
					}
					
					if (scope.mode === 'edit') {
						showBlockSelector = angular.element(
							'<div class="pull-left">'+
							'    <div class="sectionName">'+(sectionName||'Default')+'</div>'+
							'	 <button class="btn btn-primary btn-ok" ng-click="showBlockSelector(\''+sectionName+'\');"><i class="icon-add-circle"></i>{{\'portal.edit.add.block\' | translate}}</button>'+
							'</div>');
						$compile(showBlockSelector)(scope);
						elm.append(showBlockSelector);
					}

					var content = angular.element('<div></div>');
					for (i = 0; i < ngModel.$modelValue.length; ++i) {
						var blockSectionName = null;
						if ('meta' in ngModel['$$rawModelValue'][i] && 'sectionName' in ngModel['$$rawModelValue'][i].meta) {
							blockSectionName = ngModel['$$rawModelValue'][i].meta.sectionName;
						}
						if (blockSectionName == sectionName) {
								block = angular.element('<div >x</div>');

								if (scope.mode === 'edit') {
									block.attr('xpsui-portal-widget-' + ngModel.$modelValue[i].meta.type + '-edit', attrs.ngModel+'[' + i + ']');
								} else {
									block.attr('xpsui-portal-widget-' + ngModel.$modelValue[i].meta.type + '-view', attrs.ngModel+'[' + i + ']');
								}

								if (ngModel.$modelValue[i].css) {
									block.attr('class', ngModel.$modelValue[i].css.cssClass);
								}
								block.attr('index', i);


								content.append(block);
								elm.append(content);
								$compile(block)(scope);
						}
					}
				};

				scope.$on('modechange', function() {
					ngModel.$render();
				});


				log.groupEnd();
			}
		};
	}]);

}(window.angular));


