(function(angular) {
	'use strict';

	angular.module('xpsui:directives')
	.directive('xpsuiLookingGlass2', ['$compile', "$parse", 'xpsuiuriescapeFilter', '$http', function($compile, $parse, xpsuiuriescape, $http) {

		var template = '<fieldset class="flex-form-fieldset" style="padding-left: 0px; padding-right: 0px;">'
		+ '<div class="flex-form-headers" >'
		+ ' <div ng-repeat="field in fields"'
		+ '  class="flex-form-header"'
		+ '  ng-style="{\'flex\': fieldWeigth(options.fields[$index]), \'-webkit-flex\': fieldWeigth(options.fields[$index]) }">'
		+ ' {{field.title}}'
		+ ' </div>'
		+ '<div class="flex-form-header-inactive">{{\'generic.search.action\' | translate}}</div>'
		+ '</div>'
		+ '<div ng-repeat="item in data" class="flex-form-array-row row-{{($index%2)?\'odd\':\'even\'}}">'
		+ ' <div ng-repeat="field in fields" '
		+ '  ng-style="{\'flex\': fieldWeigth(options.fields[$index]), \'-webkit-flex\': fieldWeigth(options.fields[$index]) }"'
		+ '  class="flex-form-editable-data-col "'
		+ '  xpsui-by-schema-field-view xpsui-schema="field"'
		+ '  xpsui-model="{{fieldModel($index)}}">x</div>'
		+ '<div class="psui-actions-holder flex-form-data-action">'
		+ '  <a ng-repeat="a in $parent.options.actions"'
		+ '     xpsui-record-action xpsui-model="$parent.item"'
		+ '	xpsui-schema="a" class="btn-primary" type="button"'
		+ '	ng-class="{\'ibActive\':btn-primary}"'
		+ '	style="background-color: #b0b0b0; border-color: #a3a3a3;">{{a.title | translate}}</a>'
		+ '</div>'
		+ '</div>'
		+ '<div class="xpsui-array-control-noItems" ng-show="!data || data.length==0">{{options.noItemText}}</div>'
		+ '</fieldset>';

		function isEmptyObj(obj) {
			if (obj && obj.hasOwnProperty('fileId')) {
				return false;
			}

			return true;
		}

		return {
			restrict: 'A',
			scope: true,
			require: ['?^xpsuiFormControl', '?^xpsuiForm'],
			link: function(scope, element, attrs, controller) {

				var form = controller[1]
				var canRedraw = false

				var crits = []
				var fields = []
				var sorts = []
				var limit = undefined
				var skip = undefined

				scope.fields = []
				scope.data = []
				scope.options = $parse(attrs.options)(scope.$parent)

				element.html(template);
				$compile(element.contents())(scope);

				// download schema
				$http({url: '/schema/compiled/' + xpsuiuriescape(scope.options.schema), method: 'GET'})
				.then(function(data) {
					for (var i in scope.options.fields) {
						var f = _.get(data.data.properties, scope.options.fields[i].field.replace('.', '.properties.'))
						if (f) {
							scope.fields.push(f)
						}
					}
				}, function(err) {
					console.error(err)
				})
				if (form && scope.options.query) {
					var unregister2 = form.registerCalculation2(scope.options.query, function(err, val) {
						// FIXME do something with error
						if (err) {
							console.error(err);
						} else {
							if (val && val.crits) {
								crits = val.crits;
							}

							if (val && val.sorts) {
								sorts = val.sorts
							}

							if (val && val.limit) {
								limit = val.limit
							}

							if (val && val.fields) {
								fields = val.fields
							}

							if (val && val.skip) {
								skip = val.skip
							}

							redraw();
						}
					});
					scope.$on('destroy', unregister2);
				}

				scope.fieldModel = function(idx) {
					return "$parent.item." + scope.options.fields[idx].field
				}
				scope.fieldWeigth = function(field) {
					if (field.render && field.render.width) {
						if (field.render.width == 'super-narrow') {
							return '2 0 70px';
						} else if (field.render.width == 'narrow') {
							return '2 0 100px';
						} else if (field.render.width == 'wide') {
							return '50 0 150px';
						}
					}
					return '10 0 200px';
				}

				scope.$on('xpsui:data-stable', function() {
					canRedraw = true;
				})

				function redraw() {
					if (!canRedraw) {
						return
					}
					console.error(crits)
					$http({url: '/search/' + xpsuiuriescape(scope.options.schema), method: 'POST', data: {
							crits: crits,
							fields: fields,
							sorts: sorts,
							limit: limit,
							skip: skip
					}}).then(
						function(data) {
							scope.data = data.data;
						},
						function(err) {
							// FIXME do something with error
							console.error(err)
						}
					);

				}
			}

		};
	}]);

}(window.angular));
